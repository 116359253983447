/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Modal, Table, Button} from 'antd'

export default function GradeInfoModal(props) {

  const { visible, onCancel, modalContent } = props;

  const tableColumns = [
    {
      title: '课程编号',
      dataIndex: 'courseId'
    },
    {
      title: '课程名称',
      dataIndex: 'courseName'
    },
    {
      title: '课程性质',
      dataIndex: 'courseCharacter'
    },
    {
      title: '学分',
      dataIndex: 'credit'
    },
    {
      title: '成绩',
      dataIndex: 'grade',
    },
  ]

  return (
    <Modal
      title="成绩详情"
      visible={visible}
      width={'700px'}
      onCancel={onCancel}
      footer={(
        <Button
          type="primary"
          onClick={onCancel}
        >
          确认
        </Button>
      )}
    >
      <Table
        columns={tableColumns}
        rowKey="courseId"
        dataSource={modalContent}
        pagination={false}
        scroll={{ y: Number(sessionStorage.getItem('tableHeight'))}}
      />
    </Modal>
  )
}