import React from 'react'
import { Modal, List } from 'antd'

export default function VolunteerModal(props) {

  const { visible, onCancel, content } = props

  const contentData = content.map(item => `第${item.priority}志愿：${item.majorName}`)

  return (
    <Modal title="填报详情"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      className='modalDataDetail'
    >

      <List
        dataSource={contentData}
        renderItem={item => (
          <List.Item>
            {item}
          </List.Item>
        )}
      />

    </Modal>
  )
}