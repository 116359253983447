export const API = {
	// 学生
	student: {
		queryAdmissionResult: '/api/student/queryAdmissionResult', // 获取自己的录取结果
		getGradeInfo: '/api/student/getGradeInfo', // 查询学生个人成绩
		getStudentInfo: '/api/student/getStudentInfo', // 查询学生个人信息
		confirmInfo: '/api/student/confirmInfo', // 确认个人信息
		voluntaryReporting: '/api/student/voluntaryReporting', // 志愿填报
		confirmGrade: '/api/student/confirmGrade', //确认个人成绩
		queryTemporaryWishes: '/api/student/queryTemporaryWishes', // 获取学生志愿填报的暂存信息（按优先级顺序返回）
		exportStudentWishPdf: '/api/student/exportStudentWishPdf', // 导出学生志愿填报PDF
	},
	// 用户
	user: {
		login: '/api/user/login', // 用户登录
		updatePassword: '/api/user/updatePassword', // 修改密码
		queryCurrentYear: '/api/user/queryCurrentYear', // 获取当前学生年级
	},
	// 专业管理
	major: {
		getMajorPlans: '/api/major/getMajorPlans', // 获取具体的专业录取计划
		getMajorList: '/api/major/getMajorList', // 获取所有可选的专业
		getHistoricalEnrollment: '/api/major/getHistoricalEnrollment', // 获取历年招生情况
	},
	// 管理员
	admin: {
		// 普通管理员
		ordinary: {
			importStudentGrade: '/api/admin/importStudentGrade', // 导入学生成绩
			exportStudentGradeTemplate: '/api/admin/exportStudentGradeTemplate', //导出学生成绩模板
			queryGradeInfo: '/api/admin/queryGradeInfo', // 查看学生成绩
			updateGrade: '/api/admin/updateGrade', // 修改学生成绩数据
			getStudentInfoBySchoolYear: '/api/admin/getStudentInfoBySchoolYear', // 分页查看学生个人信息(按学生年级)
			importStudentInfo: '/api/admin/importStudentInfo', // 导入学生信息
			updateStudentInfo: '/api/admin/updateStudentInfo', // 修改学生个人信息
			queryStudentInfo: '/api/admin/queryStudentInfo', //分页查看学生个人信息
			exportStudentInfoTemplate: '/api/admin/exportStudentInfoTemplate', // 导出学生信息模板
			exportNotSubmittedStudentInfo: '/api/admin/exportNotSubmittedStudentInfo', //导出未填报志愿的学生信息
			exportVoluntaryResult: '/api/admin/exportVoluntaryResult', //据批次id导出志愿报名结果
			exportVoluntaryInfo: '/api/admin/exportVoluntaryInfo', //未确定前的志愿报名情况
			queryBatch: '/api/admin/queryBatch', //获取年度批次，这个接口，所有用户都可以获取
		},
		// 管理负责人
		manager: {
			getAdmin: '/api/admin/getAdmin', // 查询普通管理员
			deleteAdmin: '/api/admin/deleteAdmin', // 批量删除普通管理员
			addAdmin: '/api/admin/addAdmin', // 新增普通管理员
			queryVoluntaryResult: '/api/admin/queryVoluntaryResult', // 查看志愿填报结果(确定后)
			addBatch: '/api/admin/addBatch', // 创建分流批次
			updateCurrentYear: '/api/admin/updateCurrentYear', // 设置当前招生学年
			queryVoluntaryInfo: '/api/admin/queryVoluntaryInfo', // 查看所有学生的填报志愿情况(确定前)
			updateOfferPlan: '/api/admin/updateOfferPlan', // 修改录取计划
			exportStudentInfo: '/api/admin/exportStudentInfo', // 导出全部学生信息
			confirmResult: '/api/admin/confirmResult', //确认志愿报名结果（同时做了志愿确定工作）
			isAllVoluntary: '/api/admin/isAllVoluntary', // 根据批次id判断是否所有人都填报志愿
			startDiversion: '/api/admin/startDiversion', // 开始志愿录取（专业分流操作）
			delayBatch: '/api/admin/delayBatch', // 推迟/提前批次结束时间
		}
	}
}

export const FetchState = {
	Init: 0,
	Pending: 1,
	Success: 2,
	Failure: 3
}