import { createSlice } from '@reduxjs/toolkit';

export const managerBatch = createSlice({
    name: 'managerBatch',
    initialState: {
        data: ['beforeFetch']
    },
    reducers: {
      fetchSuccess(state, action) {
        const { payload } = action;
        state.data = payload;
      },
      confirm(state, action) {
        const { payload } = action;
        state.data = payload;
      },
  },
}).reducer;