import {
  createSlice
} from '@reduxjs/toolkit';

export const historicalEnrollment = createSlice({
  name: 'historicalEnrollment',
  initialState: {
    data: null
  },
  reducers: {
    fetchSuccess(state, action) {
      const {
        payload
      } = action;
      state.data = payload;
    },
  },
}).reducer;