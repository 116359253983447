import { createSlice } from '@reduxjs/toolkit';

export const studentsInfoSchoolYear = createSlice({
    name: 'studentsInfoSchoolYear',
    initialState: {
        data: null
    },
    reducers: {
      setData: function(state, action) {
        const { payload } = action;
        state.data = payload;
      },
    },
}).reducer;