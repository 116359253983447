/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Space, Table, message, Upload, Form } from 'antd';
import { useFetch } from '@common/hooks';
import { API, FetchState } from '@constanst';
import { GetReduxItem, ExportExcel, GetPermission, UploadFile } from '@common/utils';
import GradeInfoModal from './GradeInfoModal'
import GradeHeader from './GradeHeader'
import { useDispatch } from 'react-redux';
import { returnPagination } from '@utils/returnPagination'
import { RedoOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import './index.less'


export default function GradeManagement() {

  const dispatch = useDispatch();
  const permission = GetPermission() // 获取permission
  const { data: { expireAt, permissionList }, currentYear } = permission // 获取过期时间和权限列表
  const gradeManagement = GetReduxItem(['gradeManagement'])

  const gradeInfo = gradeManagement.data
  const searchInfo = gradeManagement.searchInfo

  const [modalContent, setModalContent] = useState([])

  if (!searchInfo.schoolYear) {
    searchInfo.schoolYear = currentYear
  }
  const [isModalVisible, setIsModalVisible] = useState(false); // 设置模态框
  const [exportLoading, setExportLoading] = useState(false)

  const [dataForm] = Form.useForm();
  const compareName = (a, b) => a.name.localeCompare(b.name, 'zh')


  // 按年份获取学生个人信息
  const [studentInfoFetchState, makeStudentInfoRequest] = useFetch({
    name: 'gradeManagement',
    url: API.admin.ordinary.getStudentInfoBySchoolYear,
    method: 'post'
  })

  // 获取学生成绩信息
  const [gradeInfoFetchState, makeGradeInfoRequest] = useFetch({
    url: API.admin.ordinary.queryGradeInfo,
    method: 'get'
  })

  // 获取成绩详情
  useEffect(() => {
    if (gradeInfoFetchState.fetchState === FetchState.Success) {
      setIsModalVisible(true);
      const modalContent = cloneDeep(gradeInfoFetchState.data.courseVo);
      setModalContent(modalContent.map(item => { item.key = item.courseId; return item; }));
    }
  }, [gradeInfoFetchState])

  // 如果首次加载页面时没有数据，则进行一次请求
  useEffect(() => {
    if (Object.keys(gradeInfo).length === 0) {
      makeStudentInfoRequest({
        data: {
          current: 1,
          size: 20,
          ...searchInfo
        }
      })
    }

  }, [])

  // 表格数据
  const tableData = gradeInfo?.records?.map(item => ({
    key: item.userId,
    ...item,
    gender: item.sex === 0 ? '女' : '男'
  }))

  // 请求学生数据成功后，设置一下
  useEffect(() => {
    if (studentInfoFetchState.fetchState === FetchState.Failure) {
      message.error('获取学生信息失败');
    }
  }, [studentInfoFetchState])

  // 设置表格头
  const TableColumns = [
    {
      title: '学生年级',
      dataIndex: 'schoolYear',
      width: '8%'
    },
    {
      title: '班级',
      dataIndex: 'clazz',
      width: '15%'
    },
    {
      title: '学号',
      dataIndex: 'schoolNumber',
      width: '12%',
      sorter: {
        compare: (a, b) => a.schoolNumber - b.schoolNumber,   //用于作排序
      }
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: '10%',
      sorter: {
        compare: (a, b) => compareName(a, b)
      }
    },
    {
      title: '性别',
      dataIndex: 'gender',
      width: '8%'
    },
    {
      title: '生源地',
      dataIndex: 'location',
      width: '8%'
    },
    {
      title: '总评成绩',
      dataIndex: 'totalScore',
      width: '10%',
      render: text => text || '无'
    },
    {
      title: '总评排名',
      dataIndex: 'studentRank',
      width: '8%',
      sorter: {
        compare: (a, b) => a.studentRank - b.studentRank,   //用于作排序
      }
    },
    {
      title: '操作',
      align: 'center',
      dataIndex: 'operation',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <Button className='table-button' onClick={() => { showModel(record); }}>查看</Button>
          </Space>
        )
      }
    }
  ];

  // 导出学生信息模板
  const exportGradeTemplate = () => {
    setExportLoading(true)
    ExportExcel(API.admin.ordinary.exportStudentGradeTemplate, expireAt, permissionList, '学生成绩模板', null, () => {
      message.destroy()
      message.success('导出成功')
      setExportLoading(false)
    })
  }

  // 上传成绩文件
  const uploadGradeFile = (file) => {
    // 上传
    UploadFile({ file }, API.admin.ordinary.importStudentGrade, expireAt, permissionList, res => {
      if (res.success === true) {
        message.destroy()
        message.success('上传成功', 1.5)
      } else {
        message.destroy()
        message.error(res.message, 1.5)
      }
    })
  }

  // 搜索年度
  const handleSearch = (values) => {
    values.schoolYear = values.schoolYear.format('YYYY')
    makeStudentInfoRequest({
      data: {
        current: 1,
        size: 20,
        ...values
      }
    });

    dispatch({
      type: 'gradeManagement/setSearchInfo',
      payload: values
    })

  }

  // 模态框函数
  // 打开模态框
  const showModel = record => {
    makeGradeInfoRequest({
      params: {
        userId: record.userId
      }
    })
  }

  // 取消按钮
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onPageChange = (page, pageSize) => {
    makeStudentInfoRequest({
      data: {
        current: page,
        size: pageSize,
        ...searchInfo
      }
    })
  }

  // 刷新表格
  const reflashTable = () => {
    makeStudentInfoRequest({
      data: {
        current: 1,
        size: 20,
        schoolYear: currentYear
      }
    })

    dataForm.resetFields()
    dataForm.setFieldsValue({
      schoolYear: moment(currentYear, 'YYYY')
    })

    dispatch({
      type: 'gradeManagement/setSearchInfo',
      payload: {}
    })
  }

  return (
    <div className="grade-container">
      <GradeHeader
        dataForm={dataForm}
        studentInfoFetchState={studentInfoFetchState}
        handleSearch={values => handleSearch(values)}
        initialValue={searchInfo}
      />
      {/* 按钮 */}
      <div className="admin-table-header">
        <Button title='刷新表格' onClick={() => { reflashTable() }}>
          <RedoOutlined />
        </Button>
        <Button
          loading={exportLoading}
          onClick={exportGradeTemplate}
          style={{ margin: '0 10px' }}>
          导出模板
          </Button>
        {/* 导入按钮 */}
        <Upload
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          beforeUpload={() => false}
          showUploadList={false}
          onChange={file => { uploadGradeFile(file.file) }}
        >
          <Button>导入成绩</Button>
        </Upload>
      </div>
      <div className="grade-content">
        <Table
          size="small"
          columns={TableColumns}
          dataSource={tableData}
          pagination={returnPagination(gradeInfo, onPageChange)}
          scroll={{ y: Number(sessionStorage.getItem('tableHeight')) }}
        />
      </div>

      {/* 修改学生信息模态框 */}
      {
        isModalVisible && (
          <GradeInfoModal
            visible={isModalVisible}
            onCancel={handleCancel}
            modalContent={modalContent}
          />
        )
      }
    </div>
  )
}