/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import UpdatePasswordForm from './UpdatePasswordForm'
import ManagementInformation from './ManagementInformation'
import ConfirmInfo from './ConfirmInfo'
import OrdinaryInformation from './OrdinaryInformation'
import { GetPermission } from '@utils'
import { API } from '@constanst';
import { useDispatch } from 'react-redux';
import { useFetch } from '@common/hooks';

import './index.less'

export default function DataManagement() {

  /**
   * confirm 是否首次登录 0 - 是   1 - 否
   * name 用户姓名
   * schoolNumber 工/学 号
   * role 权限
   * infoConfirm 是否需要确认信息 0 - 是   1 - 否
   */
  const permission = GetPermission()
  const { user: { confirm, name, schoolNumber }, role, infoConfirm } = permission.data
  const { currentYear } = permission

  const dispatch = useDispatch();

  // 获取当前年份
  const [, makeQueryCurrentYearRequest] = useFetch({
    url: API.user.queryCurrentYear,
    method: 'get'
  })

  useEffect(() => {
    if (currentYear === null) {
      makeQueryCurrentYearRequest({}, (res => {
        dispatch({
          type: 'permission/setCurrentYear',
          payload: res.data
        })
      }))
    }
  }, [])

  return (
    <Fragment>

      {
        // 学生
        ['student', 'special', '可填报志愿的特殊批'].includes(role.roleName) && (
          confirm === 0
            // 第一次登录
            ?
            <div className="forces-update-password">
              <UpdatePasswordForm />
            </div>
            // 非第一次登录
            : <ConfirmInfo infoConfirm={infoConfirm} />
        )
      }

      {
        // 普通管理员
        role.roleName === 'admin' && (
          confirm === 0
            // 第一次登录
            ? <UpdatePasswordForm />
            // 非第一次登录
            : <OrdinaryInformation
              name={name}
              description={role.description}
              schoolNumber={schoolNumber}
            />
        )
      }

      {
        // 管理负责人
        role.roleName === 'principal' &&
        <ManagementInformation />
      }
    </Fragment>
  )
}
