/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Checkbox } from 'antd';
import { useFetch } from '@common/hooks';
import { API, FetchState } from '@constanst';
import { useHistory } from 'react-router';
import { SetLocalItem, GetLocalItem, RemoveLocalItem } from '@utils'
import './index.less'


export default function Login() {

	useEffect(() => {
		// 处于登录页面时，清除localStorage里的 permission
		RemoveLocalItem('permission')
	}, [])

	// 登录请求
	const [loginFetchState, makeLoginRequest] = useFetch({
		name: 'permission',
		url: API.user.login,
		method: 'post'
	});

	const history = useHistory();

	// 登录表单的初始数据
	const [initialLoginData, setInitialLoginData] = useState(GetLocalItem('remember') || {});

	useEffect(() => {
		if (loginFetchState.fetchState === FetchState.Success) {
			history.push('/homepage/dataManagement');
			message.success('登录成功！');

			if (initialLoginData.remember) {
				// 如果选择记住我则在localStorage里面设置值
				SetLocalItem('remember', {
					account: initialLoginData.account,
					password: initialLoginData.password
				})
			} else {
				// 没选择则删除值
				RemoveLocalItem('remember')
			}
		}
		if (loginFetchState.fetchState === FetchState.Failure) {
			message.error(loginFetchState.data);
		}
	}, [loginFetchState])

	const onFinish = (values) => {
		makeLoginRequest({
			data: values
		}, () => {
			setInitialLoginData(values)
		})
	};

	return (
		// 整个登录页
		<div id="login-page">
			{/* 登录模块 */}
			<div id="login-form">
				{/* 左边信息部分 */}
				<div className="msg">
					<div className="logo"></div>
				</div>
				{/* 右边表单部分 */}
				<div className="form">
					<h1>广东工业大学计算机学院<br />专业分流系统</h1>
					<Form
						name="basic"
						initialValues={{
							remember: true,
							...initialLoginData
						}}
						onFinish={onFinish}
					>
						<Form.Item
							label="账号"
							name="account"
							rules={[
								{
									required: true,
									message: '请输入用户名!',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="密码"
							name="password"
							rules={[
								{
									required: true,
									message: '请输入密码!',
								},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							name="remember"
							valuePropName="checked"
						>
							<Checkbox
								style={{ float: 'right' }}
							>记住我</Checkbox>
						</Form.Item>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="login-btn"
								loading={loginFetchState.fetchState === FetchState.Pending}>
								登录
						</Button>
						</Form.Item>
					</Form>
					<div className="TopView">
						<span className="tv-logo"></span>
						<p>技术支持： TopView工作室</p>
					</div>
				</div>
			</div>
		</div>
	);
}
