/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react';
import { Table, message, Button, Space, Modal, Divider, Typography } from 'antd';
import { API, FetchState } from '@constanst';
import { useFetch } from '@hooks';
import './index.less';
import { GetReduxItem, GetPermission } from '@utils'
import { useDispatch } from 'react-redux'
const { Title } = Typography

export default function GradeQuery() {
  const [columns, setColumns] = useState([]);
  const [modalData, setModalData] = useState({ isModalVisible: false, data: '' });

  const [gradeFetchState, makeGradeRequest] = useFetch({
    name: 'gradeQuery',
    url: API.student.getGradeInfo,
    method: 'get'
  })

  const gradeQuery = GetReduxItem(['gradeQuery', 'data'])
  const permissionData = GetPermission().data
  const { user: { id }, infoConfirm } = permissionData
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(gradeQuery).length === 0) {
      makeGradeRequest()
    }
  }, [])

  const dataSource = gradeQuery?.courseVo?.map(item => {
    item.key = item.courseId;
    return item;
  })

  useEffect(() => {
    const columns = [
      {
        title: '课程编号',
        dataIndex: 'courseId'
      },
      {
        title: '课程名称',
        dataIndex: 'courseName'
      },
      {
        title: '课程性质',
        dataIndex: 'courseCharacter'
      },
      {
        title: '学分',
        dataIndex: 'credit',
        sorter: {
          compare: (a, b) => a.credit - b.credit
        }
      },
      {
        title: '成绩',
        dataIndex: 'grade',
        sorter: {
          compare: (a, b) => a.grade - b.grade
        }
      }
    ]
    setColumns(columns)

    if (gradeFetchState.fetchState === FetchState.Failure) {
      message.error('获取失败', 1.5)
    }
  }, [gradeFetchState])

  const isLoading = gradeFetchState.fetchState === FetchState.Pending

  const [totalGradeState, makeTotalGradeRequest] = useFetch({
    name: 'totalGrade',
    url: API.student.confirmGrade,
    method: 'get'
  })
  useEffect(() => {
    if (totalGradeState.fetchState === FetchState.Success) {
      message.success('确认成功!', 1.5)
      // 如果个人信息和成绩均未确认(0)，则修改infoConfirm为(2)已确认成绩
      // 如果已确认个人信息(1),则修改infoConfirm为(3)表示两者均已确认
      permissionData.infoConfirm = (permissionData.infoConfirm === 0 ? 2 : 3)
      dispatch({
        type: 'permission/changeConfirm',
        payload: permissionData
      })
    }
    if (totalGradeState.fetchState === FetchState.Failure) {
      message.error(totalGradeState.data, 1.5);
    }
  }, [totalGradeState])

  const handleClick = () => {
    setModalData({
      isModalVisible: true,
      data: '如信息有误，请联系管理员进行修改！信息无误请确认。'
    })
  }

  const hideModal = () => {
    setModalData({
      isModalVisible: false,
      data: ''
    })
  }

  const handleOk = () => {
    makeTotalGradeRequest({
      userId: id
    })
    setModalData({
      isModalVisible: false,
      data: ''
    })
  }

  return (
    <div>
      <div className="scoreHeader page-header">
        <Title level={2}>成绩信息</Title>
        <div className="scoreWrapper">
          {
            infoConfirm !== 3
              ? (
                <div>确认个人信息和成绩后，可查看总评和排名。</div>
              )
              : (
                <Fragment>
                  <span style={{ marginRight: '50px' }}>总评成绩：{gradeQuery.totalScore}</span>
                  <span style={{ marginRight: '100px' }}>排名：{gradeQuery.studentRank}</span>
                </Fragment>
              )
          }
        </div>
      </div>
      <Divider
        style={{ marginTop: '10px' }}
      />
      <div>
        <Table
          rowSelection={false}
          columns={columns}
          dataSource={dataSource}
          loading={isLoading}
          size="small"
          pagination={{ pageSize: 20 }}
          scroll={{ y: Number(sessionStorage.getItem('tableHeight')) }}
        />
      </div>
      <div className="buttonWrapper">
        {
          ([0, 1].includes(infoConfirm) && dataSource?.length !== 0) && (
            <Space>
              <Button
                type="primary"
                className="okButton"
                onClick={() => { handleClick() }}
              >
                确认成绩
              </Button>
            </Space>
          )
        }
      </div>
      <Modal title="提示" visible={modalData.isModalVisible} onOk={handleOk} onCancel={hideModal}>
        <p>{modalData.data}</p>
      </Modal>
    </div>
  )
}