import React from 'react';
import { Route, Router, Switch } from "react-router-dom";
import Login from '@container/Login';
import HomePage from '@container/HomePage';
import { createBrowserHistory } from 'history';
import { RouteInterception, GetPermission } from '@utils'
import moment from 'moment'
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

export const history = createBrowserHistory()

export let headerBar = []

export default function RouterComponent() {

  const studentHeaderBar = [
    { '资料管理': 'dataManagement' },
    { '成绩查询': 'queryGrade' },
    { '历年招生': 'historicalEnrollment' },
    { '志愿报名': 'voluntary' },
    { '录取查询': 'matriculation' }
  ]

  const managerHeaderBar = [
    { '资料管理': 'dataManagement' },
    { '成绩管理': 'gradeManagement' },
    { '志愿管理': 'volunteerManagement' },
    { '学生管理': 'studentsManagement' },
  ]

  const permission = GetPermission()

  if (Object.keys(permission.data).length > 0) {

    switch (permission.data.role.roleName) {
      // 普通学生
      case 'student':
      case '可填报志愿的特殊批':
        {
          headerBar = studentHeaderBar
          break
        }
      // 特殊批次学生
      case 'special': {
        studentHeaderBar.splice(3, 1)
        headerBar = studentHeaderBar
        break
      }
      // 普通管理员
      case 'admin': {
        headerBar = managerHeaderBar
        break
      }
      // 管理负责人
      case 'principal': {
        managerHeaderBar.push({ '辅助管理': 'auxiliaryManagement' })
        headerBar = managerHeaderBar
        break
      }
      default: {
        break
      }
    }

  }

  // 路由拦截
  RouteInterception(headerBar)

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/homepage" component={HomePage} />
      </Switch>
    </Router>
  )
}
