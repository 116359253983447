/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, Space, Button, Typography } from 'antd'
import { API } from '@constanst';
import { useFetch } from '@common/hooks';
import { Select, message } from 'antd'
import { GetReduxItem, GetPermission, ExportPdf } from '@utils'
import _ from 'lodash'

const { Title } = Typography

export default function VoluntaryReporting(props) {

  const { currentYear, data: { expireAt, permissionList } } = GetPermission() // 学生当前年份
  const admissionResult = GetReduxItem(['admissionResult', 'data']);  // 录取结果
  const majorList = GetReduxItem(['majorList', 'data']);  // 计划招收人数列表
  const tmpWishes = GetReduxItem(['tmpWishes', 'data']);  // 暂存的志愿填报信息
  const batchId = GetReduxItem(['batchId', 'data']);
  const accept = admissionResult[admissionResult.length - 1]?.accept || -1; // 是否被录取，可用于判断是否已经提交
  const makeAdmissionResultRequest = props.makeAdmissionResultRequest;
  const hadSubmit = [1, 2]; // 已经提交志愿填报后，accept可能的状态

  // 获取所有可选的专业
  const [, makeMajorListRequest] = useFetch({
    name: 'majorList',
    url: API.major.getMajorList,
    method: 'get'
  });

  // 获取分流批次
  const [, makeBatchIdRequest] = useFetch({
    name: 'batchId',
    url: API.admin.ordinary.queryBatch,
    method: 'get'
  })


  // 获取志愿填报的暂存信息（按优先级顺序返回）
  const [, makeTemporaryWishesRequest] = useFetch({
    name: 'tmpWishes',
    url: API.student.queryTemporaryWishes,
    method: 'get'
  })


  useEffect(() => {
    // 获取可选专业
    if (majorList[0] === 'beforeFetch') {
      makeMajorListRequest()
    }
    // 获取录取结果
    if (admissionResult[0] === 'beforeFetch') {
      makeAdmissionResultRequest()
    }
  }, [])

  // 获取分流批次
  useEffect(() => {
    if (currentYear && batchId[0] === 'beforeFetch') {
      makeBatchIdRequest({
        params: {
          year: currentYear
        }
      })
    }
  }, [currentYear])

  // 获取已经填报志愿的梯度
  useEffect(() => {
    if (batchId.length === 0) {
      message.info('当前不存在批次', 1.5)
      return
    }
    if (batchId[0] !== 'beforeFetch' && tmpWishes[0] === 'beforeFetch') {
      makeTemporaryWishesRequest({
        params: {
          batchId: batchId[batchId.length - 1].id
        }
      })
    }
  }, [batchId[batchId.length - 1]?.id])

  const VoluntaryName = new Map([
    [1, '第一志愿'],
    [2, '第二志愿'],
    [3, '第三志愿'],
    [4, '第四志愿'],
    [5, '第五志愿'],
    [6, '第六志愿'],
    [7, '第七志愿'],
    [8, '第八志愿'],
    [9, '第九志愿'],
    [10, '第十志愿'],
  ])

  const options = majorList.map(item => (
    {
      key: item.id,
      value: item.id,
      label: item.majorName,
    }
  ))

  // 把已填写的志愿梯度填入表单中
  useEffect(() => {
    if (tmpWishes[0] !== 'beforeFetch' && majorList[0] !== 'beforeFetch' && majorList.length !== 0) {
      const fieldsValue = {};
      tmpWishes.forEach(item => {
        fieldsValue[item.priority + '-Vol'] = item.majorId
      })
      form.setFieldsValue(fieldsValue);
    }
  }, [JSON.stringify(tmpWishes)])

  const [form] = Form.useForm();

  const onSelectChange = () => {
    // 获取表单的data从中判断是否加disabled
    const data = form.getFieldValue()
    // 遍历判断加disable
    options.forEach(item => {
      item.disabled = false
    })
    Object.keys(data).forEach(key => {
      if (data[key] !== undefined) {
        options[_.findIndex(options, { key: data[key] })].disabled = true
      }
    })
  }

  const { setSubmitType, setModalData } = props

  const onFinish = formData => {
    setModalData({
      isModalVisible: true,
      formData
    })
  }

  const exportStudentWishPdf = () => {
    ExportPdf(API.student.exportStudentWishPdf, expireAt, permissionList, '志愿填报结果', {
      batchId: batchId[batchId.length - 1].id
    })
  }

  return (

    <div className="voluntary-right">
      <Title level={3}>志愿填写</Title>

      {
        // 不为 beforeFetch 说明请求成功
        majorList[0] !== 'beforeFetch' &&
        (majorList.length === 0
          // 如果请求到的数据为空
          ? (<div>当前阶段没有可填报的专业</div>)
          // 有数据则进行展示
          : (
            <Form
              form={form}
              onFinish={values => onFinish(values)}
            >
              {
                majorList.map((item, index) => {
                  if (tmpWishes[0] !== 'beforeFetch' && tmpWishes.length !== 0 && majorList[0] !== 'beforeFetch' && majorList.length !== 0) {
                    options.forEach(item => {
                      item.disabled = true
                    })
                  }
                  return (
                    <Form.Item
                      key={item.id}
                      label={VoluntaryName.get(index + 1)}
                      name={(index + 1) + '-Vol'}
                      rules={[{
                        required: true,
                      }]}
                    >
                      <Select
                        options={options}   // 设置当前option
                        onChange={onSelectChange}
                        disabled={hadSubmit.includes(accept)}
                        allowClear
                      />
                    </Form.Item>
                  )
                })
              }
              {
                hadSubmit.includes(accept) === false ? (
                  <Form.Item style={{ textAlign: 'center' }}>
                    <Space size={'middle'}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => { setSubmitType(1) }}
                      >
                        暂存
                    </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => { setSubmitType(2) }}
                      >
                        提交
                    </Button>
                    </Space>
                  </Form.Item>) : (
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        onClick={exportStudentWishPdf}
                      >
                        导出填报结果
                      </Button>
                    </div>

                  )
              }
            </Form>
          )
        )}
    </div>
  )
}