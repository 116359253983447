/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { Divider, Typography, Button, Table, message, Form, Input, DatePicker, Upload, Dropdown, Menu } from 'antd'
import { API, FetchState } from '@constanst';
import { useDispatch } from 'react-redux';
import { useFetch } from '@common/hooks';
import { GetReduxItem, GetPermission, UploadFile, ExportExcel } from '@utils'
import Enum from '@utils'
import { RedoOutlined } from '@ant-design/icons';
import moment from "moment"
import ChangeInfoModal from './ChangeInfoModal'
import { returnPagination } from '@utils/returnPagination'

import './index.less'

const { Title } = Typography

export default function StudentsManagement() {

  // 分页查看学生个人信息
  const [studentInfoFetchState, makeStudentInfoRequest] = useFetch({
    name: 'studentInfomation',
    url: API.admin.ordinary.getStudentInfoBySchoolYear,
    method: 'POST'
  });

  // 修改学生个人信息
  const [updatestudentFetchState, makeupdateStudentRequest] = useFetch({
    name: 'updateStudentInfo',
    url: API.admin.ordinary.updateStudentInfo,
    method: 'POST'
  });

  const dispatch = useDispatch()

  const studentInfo = GetReduxItem(['studentInfomation', 'data']) // redux拿数据放table
  const searchInfo = GetReduxItem(['studentInfomation', 'searchInfo']) // redux拿数据放table

  const permission = GetPermission() // 获取permission
  const { expireAt, permissionList } = permission.data // 获取过期时间和权限列表
  const { currentYear } = permission

  if (!searchInfo.schoolYear) {
    searchInfo.schoolYear = currentYear
  }

  const [columns, setColumns] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false); // 设置模态框
  const [formUserId, setFormUserId] = useState(null); // 设置userid
  const [searchForm] = Form.useForm();  // 获取表单实例
  const [form] = Form.useForm();     // 获取表单实例
  const sexEnum = new Enum(['女', '男'])
  const batchEnum = new Enum(['否', '是'])

  // 获取 majorPlan 和 currentYear
  useEffect(() => {

    const columns = [
      {
        title: '学生年级',
        dataIndex: 'schoolYear',
      },
      {
        title: '班级',
        dataIndex: 'clazz',
      },
      {
        title: '学号',
        dataIndex: 'schoolNumber',
        sorter: {
          compare: (a, b) => a.schoolNumber - b.schoolNumber,
        },
      },
      {
        title: '姓名',
        dataIndex: 'name',
      },
      {
        title: '性别',
        dataIndex: 'sex',
        render: text => sexEnum[text]
      },
      {
        title: '生源地',
        dataIndex: 'location',
      },
      {
        title: '是否特殊批次',
        dataIndex: 'batchType',
        render: text => batchEnum[text]
      },
      {
        title: '排名',
        dataIndex: 'studentRank',
        sorter: {
          compare: (a, b) => a.studentRank - b.studentRank,
        },
      },
      {
        title: '操作',
        dataIndex: 'button',
        render: (_, record) => <Button onClick={() => { showModel(record) }}>修改</Button>
      }
    ];
    setColumns(columns);


    const values = JSON.parse(JSON.stringify(searchInfo))
    values.schoolYear = moment(values.schoolYear, 'YYYY')
    searchForm.setFieldsValue({ ...values })
  }, [])

  useEffect(() => {
    // 请求表格数据
    if (Object.keys(studentInfo).length === 0) {
      makeStudentInfoRequest({
        data: {
          current: 1,
          size: 20,
          ...searchInfo
        }
      });
    }
  }, [])

  // 表格请求
  useEffect(() => {
    if (studentInfoFetchState.fetchState === FetchState.Failure) {
      message.error('表格获取失败！', 1.5);
    }
  }, [studentInfoFetchState])

  // 学生修改
  useEffect(() => {
    if (updatestudentFetchState.fetchState === FetchState.Success) {
      setIsModalVisible(false);
      // 刷新(刷新后仍是搜索框内容)
      makeStudentInfoRequest({
        data: {
          current: 1,
          size: 20,
          ...searchInfo
        }
      })
      message.success('修改成功！', 1.5);
    }

    if (updatestudentFetchState.fetchState === FetchState.Failure) {
      message.warning(updatestudentFetchState.data || '修改失败！', 1.5);
    }
  }, [updatestudentFetchState])


  const onPageChange = (page, pageSize) => {
    makeStudentInfoRequest({
      data: {
        current: page,
        size: pageSize,
        ...searchInfo
      }
    });
  }

  // 搜索框
  const onFinish = (values) => {
    values.schoolYear = moment(values.schoolYear).format('YYYY')  // 获取后转格式
    makeStudentInfoRequest({  // 发请求
      data: {
        current: 1,
        size: 20,
        ...values
      }
    })

    dispatch({
      type: 'studentInfomation/setSearchInfo',
      payload: values
    })

  };

  // 模态框函数
  // 打开模态框
  const showModel = (record) => {
    setIsModalVisible(true);
    setFormUserId(record.userId)  // 拿到id
    form.resetFields(); // 清空模态框里表单的值
    form.setFieldsValue(record)
  }

  // 取消按钮
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 模态框表单完成事件
  const handleOk = () => {
    form.validateFields().then(() => {  // 校验并发请求
      const data = {
        ...form.getFieldsValue(),
        userId: formUserId
      }
      makeupdateStudentRequest({ data })
    })
  };

  // 刷新表格
  const reflashTable = () => {

    makeStudentInfoRequest({  // 刷新
      data: {
        current: 1,
        size: 20,
        schoolYear: currentYear
      }
    })

    searchForm.resetFields()
    searchForm.setFieldsValue({
      schoolYear: moment(currentYear, 'YYYY')
    })

    dispatch({
      type: 'studentInfomation/setSearchInfo',
      payload: {}
    })
  }

  // 上传学生信息
  const importStudentInfo = file => {
    // 上传
    UploadFile({ file }, API.admin.ordinary.importStudentInfo, expireAt, permissionList, res => {
      if (res.success === true) {
        message.destroy()
        message.success('上传成功', 1.5)
        reflashTable()
      } else {
        message.destroy()
        message.error(res.message, 1.5)
      }
    })
  }

  // 导出学生信息模板
  const exportStudentInfoTemplate = () => {
    ExportExcel(API.admin.ordinary.exportStudentInfoTemplate, expireAt, permissionList, '学生信息模板')
  }

  // 导出学生全部信息
  const exportStudentInfo = () => {
    ExportExcel(API.admin.manager.exportStudentInfo, expireAt, permissionList, '全部学生信息')
  }


  const exportMenu = (
    <Menu>
      <Menu.Item
        onClick={exportStudentInfoTemplate}
      >
        导出模板
      </Menu.Item>
      <Menu.Item
        onClick={exportStudentInfo}
      >
        全部学生信息
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      {/* 标题 */}
      {/* 用户管理header */}
      <div className="management-header page-header" >
        <Title level={2}>学生管理</Title>
        {/* 搜索框 */}
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="searchAdmin"
          form={searchForm}
          onFinish={onFinish}
        >
          <Form.Item
            label="班级"
            name="clazz"
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="学号"
            name="schoolNumber"
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="姓名"
            name="name"
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="年级"
            name="schoolYear"
          >
            <DatePicker
              picker="year"
              allowClear={false}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit" style={{ marginLeft: '10px' }}
            >
              查询
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Divider
        style={{ marginTop: '10px' }}
      />

      {/* 按钮 */}
      <div className="admin-table-header">
        {/* 刷新按钮 */}
        <Button title='刷新表格' onClick={reflashTable}>
          <RedoOutlined />
        </Button>
        {/* 导出按钮 */}
        <Dropdown overlay={exportMenu}>
          <Button style={{ margin: '0 10px' }}>导出</Button>
        </Dropdown>
        {/* 导入按钮 */}
        <Upload
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          beforeUpload={() => false}
          showUploadList={false}
          onChange={file => { importStudentInfo(file.file) }}
        >
          <Button>导入</Button>
        </Upload>
      </div>

      {/* 底部 */}
      <Table
        className={'admin-table'}
        columns={columns} // 表头配置
        dataSource={studentInfo?.records || studentInfoFetchState.data?.records} // 表单数据
        rowKey={'userId'} // 选择key值
        loading={studentInfoFetchState.fetchState === FetchState.Pending} // loading
        size="small"
        pagination={returnPagination(studentInfo, onPageChange)} // 行配置
        scroll={{ y: Number(sessionStorage.getItem('tableHeight')) }}
      />

      {/* 修改学生信息模态框 */}
      <ChangeInfoModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        formRef={form}
      />

    </Fragment>
  )
}