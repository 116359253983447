import axios from 'axios';
import md5 from 'blueimp-md5';

const requestMap = new Map();

export const fetchMiddleware = _storeAPI => next => async action => {
	// 如果不是从useFetch进来的，那么就是普通的dispatch
	if (!action.fetchInfo) {
		return next(action);
	}
	const {
		requestConfig,
		requestId,
		name
	} = action.fetchInfo;
	const callback = action.callback;
	const resetRequestId = action.resetRequestId;
	next({
		type: `fetch/fetchPending`,
		payload: {
			requestId,
		}
	})

	let request, response;
	// 如果是相同的请求那么就不重复发
	const requestKey = md5(JSON.stringify(requestConfig));
	if (requestMap.get(requestKey)) {
		request = requestMap.get(requestKey);
	} else {
		request = axios(requestConfig).then(res => {
				response = res.data;
				callback && callback(response);
			})
			.catch(e => {
				next({
					type: `fetch/fetchFailure`,
					payload: {
						requestId,
						data: e.message
					}
				})
			});
		requestMap.set(requestKey, request);
	}
	await request;

	if (response) {
		if (response.success) {
			next({
				type: `fetch/fetchSuccess`,
				payload: {
					requestId,
					data: response.data,
				}
			})
			if (name) {
				next({
					type: `${name}/fetchSuccess`,
					payload: response.data
				})
			}
			resetRequestId()
		} else {
			next({
				type: `fetch/fetchFailure`,
				payload: {
					requestId,
					data: response.message,
				}
			})
		}
	}

	requestMap.delete(requestKey);
}