import {
  configureStore
} from '@reduxjs/toolkit';
import {
  fetchMiddleware
} from './middleware';
import {
  permission,
  fetch,
  info,
  studentScore,
  historicalEnrollment,
  managementInformation,
  gradeManagement,
  ordinaryVolunteer,
  principleVolunteer,
  gradeQuery,
  majorPlan,
  studentInfomation,
  studentsInfoSchoolYear,
  majorList,
  batchId,
  managerBatch,
  matriculation,
  tmpWishes,
  admissionResult,
  queryVoluntaryResult,
} from './reducer';

const store = configureStore({
  reducer: {
    permission,
    fetch,
    info,
    studentScore,
    historicalEnrollment,
    gradeQuery,
    managementInformation,
    gradeManagement,
    ordinaryVolunteer,
    principleVolunteer,
    majorPlan,
    studentInfomation,
    studentsInfoSchoolYear,
    majorList,
    batchId,
    managerBatch,
    matriculation,
    tmpWishes,
    admissionResult,
    queryVoluntaryResult
  },
  middleware: (getDefaultMiddleware) => [fetchMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})

export default store;