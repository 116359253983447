/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useRef
} from 'react';
import {
  FetchState,
  API
} from '@constanst';
import {
  useDispatch,
  shallowEqual,
  useSelector
} from 'react-redux';
import md5 from 'blueimp-md5';
import {
  GetPermission,
  NotInterceptRequest,
  GetToken
} from '@utils'
import "antd/dist/antd.css";

export function useFetch(options) {

  const requestRef = useRef(md5(Math.random()))

  const {
    name = '', ...option
  } = options;
  const dispatch = useDispatch();

  const fetchState = useSelector(state => state.fetch[requestRef.current] || {}, shallowEqual);

  const resetRequestId = () => {
    requestRef.current = md5(Math.random())
  }

  useEffect(() => {
    dispatch({
      type: 'fetch/fetchInit',
      payload: {
        requestId: requestRef.current,
        data: {},
        fetchState: FetchState.Init
      }
    });
  }, [requestRef.current])

  const {
    expireAt, // 过期时间
    permissionList // 权限列表
  } = GetPermission().data

  const makeRequest = useCallback(
    (extraOption, callback) => {

      const requestConfig = Object.assign({}, option, extraOption);

      // 非登录接口
      if (requestConfig.url !== API.user.login) {
        if (NotInterceptRequest(requestConfig.url, expireAt, permissionList) === true) {
          const Token = GetToken()
          requestConfig.headers = {
            ...(requestConfig.headers || {}),
            Authorization: Token
          }
        } else {
          return
        }
      }

      const action = {
        fetchInfo: {
          requestId: requestRef.current,
          requestConfig,
          name
        },
        callback,
        resetRequestId
      };

      dispatch(action);
    },
    [requestRef.current],
  )

  return [fetchState, makeRequest]
}