import React from 'react'
import { Modal, Form, Input, Radio } from 'antd'
import  Enum from '@utils'

export default function ChangeInfoModal(props) {

  const { visible, onOk, onCancel, formRef } = props;
  const sexEnum = new Enum(['女', '男'])
  const batchEnum = new Enum(['否', '是'])

  return (
    <Modal
      title="修改学生信息"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="updateStudent"
        form={formRef}
      >
        <Form.Item
          label="姓名"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="性别"
          name="sex"
        >
          <Radio.Group options={sexEnum.toOptions()} />
        </Form.Item>
        <Form.Item
          label="班级"
          name="clazz"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="生源地"
          name="location"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="是否特殊批次"
          name="batchType"
        >
          <Radio.Group options={batchEnum.toOptions()} />
        </Form.Item>
        <Form.Item
          label="学生新密码"
          name="password"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}