import {
  createSlice
} from '@reduxjs/toolkit';

export const ordinaryVolunteer = createSlice({
  name: 'ordinaryVolunteer',
  initialState: {
    data: {},
    searchInfo: {}
  },
  reducers: {
    fetchSuccess(state, action) {
      const {
        payload
      } = action;
      state.data = payload;
    },
    setSearchInfo(state, action) {
      const {
        payload
      } = action;
      state.searchInfo = payload;
    }
  },
}).reducer;