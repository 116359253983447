/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { API, FetchState } from '@constanst';
import { useFetch } from '@hooks';
import { Table, message } from 'antd'
import { GetReduxItem } from '@utils'
import VolunteerHeader from '../VolunteerHeader'
import { returnPagination } from '@utils/returnPagination'
import { useDispatch } from 'react-redux';

export default function OrdinaryVolunteer(props) {

  const [volunteerFetchState, makeVolunteerRequest] = useFetch({
    name: 'ordinaryVolunteer',
    url: API.admin.ordinary.queryStudentInfo,
    method: 'post'
  })
  const [columns, setColumns] = useState([]);
  const ordinaryInfo = GetReduxItem(['ordinaryVolunteer', 'data'])
  const searchInfo = GetReduxItem(['ordinaryVolunteer', 'searchInfo'])
  const { batchId } = props
  const isLoading = volunteerFetchState.fetchState === FetchState.Pending;
  const dispatch = useDispatch()


  const onPageChange = (page, pageSize) => {
    makeVolunteerRequest({
      data: {
        current: page,
        size: pageSize,
        batchId,
        ...searchInfo
      }
    })
  }

  // 搜索框点击查询时
  const onFinish = (values) => {

    if (batchId === undefined) {
      message.info('当前不存在批次', 1.5)
      return
    }

    makeVolunteerRequest({  // 发请求
      data: {
        current: 1,
        size: 20,
        batchId,
        ...values
      }
    })

    dispatch({
      type: 'ordinaryVolunteer/setSearchInfo',
      payload: values
    })

  };

  useEffect(() => {
    const columns = [
      {
        title: '年级',
        dataIndex: 'schoolYear',
        sorter: {
          compare: (a, b) => a.schoolYear - b.schoolYear,   //用于作排序
        },
      },
      {
        title: '班级',
        dataIndex: 'clazz'
      },
      {
        title: '学号',
        dataIndex: 'schoolNumber',
        sorter: {
          compare: (a, b) => a.schoolNumber - b.schoolNumber,   //用于作排序
        },
      },
      {
        title: '姓名',
        dataIndex: 'name'
      },
      {
        title: '是否提交志愿',
        dataIndex: 'status',
        render: text => {
          if (text === 1) {
            return '未提交'
          } else {
            return '已提交'
          }
        }
      }
    ];
    setColumns(columns);
  }, [])

  useEffect(() => {
    // 如果没有就数据就请求
    if (Object.keys(ordinaryInfo).length === 0 && batchId)
      makeVolunteerRequest({
        data: {
          current: 1,
          size: 10,
          batchId
        }
      })
  }, [batchId])

  useEffect(() => {
    if (volunteerFetchState.fetchState === FetchState.Failure) {
      message.error('获取失败！', 1.5);
    }
  }, [volunteerFetchState])

  return (
    <Fragment>

      {/* 表格头 */}
      <VolunteerHeader
        onFinish={onFinish}
        makeRequest={makeVolunteerRequest}
        batchId={batchId}
        initialValue={searchInfo}
      />

      <div>
        <Table
          rowKey="userId"
          rowSelection={false}
          columns={columns}
          dataSource={ordinaryInfo.records}
          loading={isLoading}
          size={'small'}
          pagination={returnPagination(ordinaryInfo, onPageChange)}   // 行配置
          scroll={{ y: Number(sessionStorage.getItem('tableHeight')) }}
        />
      </div>
    </Fragment>
  )
}