import React from 'react';
import { Layout, Menu, Card, Dropdown } from 'antd';
import { Switch, Route, Link } from "react-router-dom";
import { SetLocalItem, GetPermission, RemoveLocalItem, RouteInterceptionFUP } from '@utils'
import { DownOutlined } from '@ant-design/icons';
import { headerBar } from '@src/router'
import './index.less'

import Voluntary from '@container/Voluntary';
import DataManagement from '@container/DataManagement';
import GradeQuery from '@container/GradeQuery'
import HistoricalEnrollment from '@container/HistoricalEnrollment';
import Matriculation from '@container/Matriculation';
import GradeManagement from '@container/GradeManagement';
import VolunteerManagement from '@container/VolunteerManagement'
import AuxiliaryManagement from '@container/AuxiliaryManagement'
import StudentsManagement from '@container/StudentsManagement'

const { Header, Content } = Layout;

export default function HomePage() {

  // 获取角色信息
  const permission = GetPermission()
  const { role, user } = permission.data

  // 是否需要强制修改密码
  const needChangePsw = (user.confirm === 0)

  // 如果强行切换路由，则跳转回来
  if (needChangePsw) {
    RouteInterceptionFUP()
  }

  // 在刷新前把数据存到localstorage里边，防止报错
  window.onbeforeunload = () => {
    SetLocalItem('permission', permission)
  }

  // 退出登录下拉框
  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={() => {
        RemoveLocalItem('JessTk')
        window.location.replace('/')
      }}>
        退出登录
      </Menu.Item>
    </Menu>
  )

  sessionStorage.setItem('tableHeight', parseInt((document.documentElement.clientWidth - 650) / 2.2, 10))

  return (
    <Layout className="layout">
      {/* 头部导航栏 */}
      <Header>
        <div className="logo">广东工业大学计算机学院专业分流系统</div>

        <div style={{ float: 'right' }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="user-msg">
              <span>{`${user.name} - ${role.description}`}</span>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>


        <Menu
          style={{ float: 'right' }}
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[`${window.location.pathname.replace(/\/(.*)\//, '')}`]}
        >
          {
            headerBar.map(bar => {
              const key = Object.keys(bar)[0] // 路由中文名
              const value = bar[key] // 路由英文名
              // 如果处于必须修改密码的状态，则其他路由按钮不允许被按
              let disabled = false
              if (needChangePsw) {
                disabled = value !== 'dataManagement' && true
              }
              return (
                <Menu.Item key={value} disabled={disabled}>
                  <Link to={`/homepage/${value}`}>{key}</Link>
                </Menu.Item>
              )
            })
          }
        </Menu>

      </Header>

      {/* 内容区 */}
      <Content style={{
        height: 'calc(100vh - 67px)',
        maxHeight: 'calc(100vh - 67px)',
        overflow: 'auto',
      }}>
        <Card
          style={{
            minHeight: 'calc(100% - 40px)',
            margin: '20px 20px 0'
          }}
          bordered={false}
        >
          {/* 二级路由 */}
          <Switch>
            <Route path="/homepage/dataManagement" component={DataManagement} />
            <Route path="/homepage/queryGrade" component={GradeQuery} />
            <Route path="/homepage/historicalEnrollment" component={HistoricalEnrollment} />
            <Route path="/homepage/voluntary" component={Voluntary} />
            <Route path="/homepage/gradeManagement" component={GradeManagement} />
            <Route path="/homepage/matriculation" component={Matriculation} />
            <Route path="/homepage/VolunteerManagement" component={VolunteerManagement} />
            <Route path="/homepage/auxiliaryManagement" component={AuxiliaryManagement} />
            <Route path="/homepage/studentsManagement" component={StudentsManagement} />
          </Switch>
        </Card>
      </Content>

    </Layout>
  )
}