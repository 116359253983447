/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { API, FetchState } from '@constanst';
import { useFetch } from '@hooks';
import { Table, message, Button } from 'antd';
import { GetReduxItem } from '@utils'
import VolunteerModal from './VolunteerModal';
import VolunteerHeader from '../VolunteerHeader'
import { useDispatch } from 'react-redux';
import { returnPagination } from '@utils/returnPagination'

export default function PrincipalVolunteer(props) {

  // 查询学生志愿
  const [principleVoFetchState, makeprincipleVoRequest] = useFetch({
    name: 'principleVolunteer',
    url: API.admin.manager.queryVoluntaryInfo,
    method: 'post'
  })

  // 查看志愿填报结果
  const [, makeQueryVoluntaryResultRequest] = useFetch({
    name: 'queryVoluntaryResult',
    url: API.admin.manager.queryVoluntaryResult,
    method: 'post'
  })

  const [modalContent, setModalContent] = useState([])
  const principleVoInfo = GetReduxItem(['principleVolunteer', 'data'])
  const queryVoluntaryResult = GetReduxItem(['queryVoluntaryResult', 'data'])
  const searchInfo = GetReduxItem(['principleVolunteer', 'searchInfo'])
  const isLoading = principleVoFetchState.fetchState === FetchState.Pending
  const { isVolunteerConfirm, batchId } = props
  const dispatch = useDispatch()
  const onPageChange = (page, pageSize) => {
    if ([1, 2].includes(isVolunteerConfirm)) {
      makeQueryVoluntaryResultRequest({
        data: {
          current: page,
          size: pageSize,
          batchId,
          ...searchInfo
        }
      })
    } else {
      makeprincipleVoRequest({
        data: {
          current: page,
          size: pageSize,
          batchId,
          ...searchInfo
        }
      })
    }

  }

  //查看填报志愿详情模态框设置
  const [isModalVisible, setIsModalVisible] = useState(false);

  //展示模态框
  const showModal = () => setIsModalVisible(true);

  // 隐藏模态框
  const handleCancel = () => setIsModalVisible(false)

  // 搜索框点击查询时
  const onFinish = values => {

    if (batchId === undefined) {
      message.info('当前不存在批次', 1.5)
      return
    }

    if ([1, 2].includes(isVolunteerConfirm)) {
      makeQueryVoluntaryResultRequest({
        data: {
          current: 1,
          size: 20,
          batchId,
          ...values
        }
      })
    } else {
      makeprincipleVoRequest({
        data: {
          current: 1,
          size: 20,
          batchId,
          ...values
        }
      })
    }

    dispatch({
      type: 'principleVolunteer/setSearchInfo',
      payload: values
    })

  };


  useEffect(() => {

    if (Object.keys(principleVoInfo).length === 0 && batchId)
      makeprincipleVoRequest({
        data: {
          current: 1,
          size: 20,
          batchId
        }
      })

  }, [batchId])

  useEffect(() => {
    if (principleVoFetchState.fetchState === FetchState.Failure) {
      message.error('获取失败！', 1.5);
    }
  }, [principleVoFetchState])

  useEffect(() => {
    if ([1, 2].includes(isVolunteerConfirm) && Object.keys(queryVoluntaryResult).length === 0)
      makeQueryVoluntaryResultRequest({
        data: {
          current: 1,
          size: 20,
          batchId
        }
      })

  }, [isVolunteerConfirm])


  let columns = [
    {
      title: '班级',
      dataIndex: 'clazz'
    },
    {
      title: '学号',
      dataIndex: 'schoolNumber',
      sorter: {
        compare: (a, b) => a.schoolNumber - b.schoolNumber,   //用于作排序
      },
    },
    {
      title: '姓名',
      dataIndex: 'name'
    },
    {
      title: '志愿详情',
      dataIndex: 'wishVoList',
      render: record => {
        if (record?.length === 0) {
          return '未提交'
        }
        return (
          <Button
            type='primary'
            onClick={() => {
              showModal(record)
              setModalContent(record)
            }}
          >
            详情
          </Button>
        )
      }
    }
  ];

  if ([1, 2].includes(isVolunteerConfirm)) {
    columns = [
      {
        title: '班级',
        dataIndex: 'clazz'
      },
      {
        title: '学号',
        dataIndex: 'schoolNumber',
        sorter: {
          compare: (a, b) => a.schoolNumber - b.schoolNumber,   //用于作排序
        },
      },
      {
        title: '姓名',
        dataIndex: 'name'
      },
      {
        title: '分流结果',
        dataIndex: 'majorName',
        shouldCellUpdate: () => {}
      }
    ]
  }

  return (
    <Fragment>

      {/* 表格头 */}
      <VolunteerHeader
        onFinish={onFinish}
        makeRequest={[1, 2].includes(isVolunteerConfirm) ? makeQueryVoluntaryResultRequest : makeprincipleVoRequest}
        batchId={batchId}
        showBtn={isVolunteerConfirm !== 1}
        initialValue={searchInfo}
      />

      {/* 表格 */}
      <Table
        rowKey='schoolNumber'
        columns={columns}
        dataSource={[1, 2].includes(isVolunteerConfirm) ? queryVoluntaryResult.records : principleVoInfo.records}
        size={'small'}
        loading={isLoading}
        pagination={[1, 2].includes(isVolunteerConfirm) ? returnPagination(queryVoluntaryResult, onPageChange) : returnPagination(principleVoInfo, onPageChange)}   // 行配置
        scroll={{ y: Number(sessionStorage.getItem('tableHeight')) }}
      />

      {/* 志愿详情模态框 */}
      <VolunteerModal
        visible={isModalVisible}
        onCancel={handleCancel}
        content={modalContent}
      />

    </Fragment>
  )
}