/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { API, FetchState } from '@constanst';
import { useFetch } from '@common/hooks';
import { Modal, message, Spin, Divider, Typography } from 'antd';
import { GetReduxItem } from '@utils'
import MajorPlanList from './MajorPlanList'
import VoluntaryReporting from './VoluntaryReporting'

import './index.less'
const { Title } = Typography
export default function Voluntary() {

  // 填报志愿的请求
  const [voluntaryFetchState, makeVoluntaryRequest] = useFetch({
    url: API.student.voluntaryReporting,
    method: 'post'
  })

  // 获取自己的录取结果，用返回的accept来，判断是否渲染按钮，禁用表单
  const [, makeAdmissionResultRequest] = useFetch({
    name: 'admissionResult',
    url: API.student.queryAdmissionResult,
    method: 'get'
  })

  // 获取志愿填报的暂存信息（按优先级顺序返回）
  const [, makeTemporaryWishesRequest] = useFetch({
    name: 'tmpWishes',
    url: API.student.queryTemporaryWishes,
    method: 'get'
  })

    // 获取批次
    const batchId = GetReduxItem(['batchId', 'data'])

  // 重设请求
  useEffect(() => {
    if (voluntaryFetchState.fetchState === FetchState.Success) {
      makeAdmissionResultRequest();
      makeTemporaryWishesRequest({
        params: {
          batchId: batchId[batchId.length - 1].id
        }
      });
      message.success(`${submitType === 1 ? '暂存' : '提交'}成功`, 1.5);
    }
    if (voluntaryFetchState.fetchState === FetchState.Failure) {
      message.error(voluntaryFetchState.data, 1.5);
    }
  }, [voluntaryFetchState])

  // 提交方式，1 - 暂存 或 2 - 提交
  const [submitType, setSubmitType] = useState(1);

  // 模态框数据
  const [modalData, setModalData] = useState({
    isModalVisible: false,
    formData: {},
  });

  // 隐藏模态框
  const hideModal = () => {
    setModalData({ isModalVisible: false })
  }

  // 对话框的确定按钮
  const handleOk = () => {
    // 获取表单数据
    const { formData } = modalData
    // 批次id
    const id = batchId[batchId.length - 1]?.id

    if (id === undefined) {
      message.info('当前不存在批次', 1.5)
      setModalData({ isModalVisible: false })
      return
    }

    // 把表单数据设置成符合请求规范的数据
    const voluntaryMessageList = Object.keys(formData).map(key => (
      {
        majorId: formData[key],
        priority: Number(key.replace('-Vol', ''))
      }
    ))
    const data = {
      voluntaryMessageList,
      status: submitType,
      batchId: id
    }
    // 发送提交请求
    makeVoluntaryRequest({ data })
    // 隐藏模态框
    setModalData({ isModalVisible: false })
  }

  // 设置加载状态
  let spinning = false
  const majorPlan = GetReduxItem(['majorPlan', 'data'])
  const majorList = GetReduxItem(['majorList', 'data'])
  if (majorPlan[0] === 'beforeFetch' || majorList[0] === 'beforeFetch') {
    // 如果里面没数据的话，则证明专业计划和可选专业没加载出来，把加载状态设为true
    spinning = true
  } else {
    // 否则则把加载状态设为发送请求的状态
    spinning = voluntaryFetchState.fetchState === FetchState.Pending
  }

  return (
    <Spin tip="加载中..." size="large" spinning={spinning} >
      <header className='page-header'>
        <Title level={2}>志愿报名填写</Title>
      </header>
      <Divider
        style={{ marginTop: '10px' }}
      />

      <div className="voluntary-content">
        {/* 左边专业计划部分 */}
        <MajorPlanList />

        {/* 右边选择部分 */}
        <VoluntaryReporting
          setSubmitType={setSubmitType}
          setModalData={setModalData}
          makeAdmissionResultRequest={makeAdmissionResultRequest}
        />
      </div>

      <Modal
        title="提示"
        visible={modalData.isModalVisible}
        onOk={handleOk}
        onCancel={hideModal}>
        <p>{submitType === 1 ? '暂存信息' : '提交后不能修改，请确认是否提交'}</p>
      </Modal>

    </Spin>
  )
}