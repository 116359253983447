import {
  shallowEqual,
  useSelector
} from 'react-redux';
import axios from 'axios';
import {
  message,
  Modal
} from 'antd'

/**
 * 获取值的类型
 * @param {*} value
 */
export const GetValueType = value => {
  return Object.prototype.toString.call(value).slice(8, -1)
}

/**
 * 获取localstorage里的值
 * @param {string} key
 */
export const GetLocalItem = key => {
  return JSON.parse(localStorage.getItem(key))
}

/**
 * 获取token值
 */
export const GetToken = () => {
  return localStorage.getItem('JessTk')
}

/**
 * 设置localstorage里的值
 * @param {string} key
 * @param {object} value
 */
export const SetLocalItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * 删除localstorage中的某一项
 * @param {string} key
 */
export const RemoveLocalItem = key => {
  localStorage.removeItem(key)
}

/**
 * 获取redux中的某一项
 * @param {string array} item
 */
export const GetReduxItem = item => {

  let result = null
  let data = JSON.parse(JSON.stringify(useSelector(state => state || {}, shallowEqual)))

  const dfs = index => {
    if (item[index] in data) {
      if (item.length === index + 1) {
        result = data[item[index]]
      } else {
        data = data[item[index]]
        dfs(++index)
      }
    } else {
      result = null
    }
  }
  dfs(0)

  return result
}

/**
 * 获取permission
 */
export const GetPermission = () => {
  let permission = useSelector(state => state.permission || {}, shallowEqual)

  // 如果permission的data里没有值，则尝试从localstorage里获取
  if (Object.keys(permission.data).length === 0) {
    const _permission = GetLocalItem('permission')
    if (GetValueType(_permission) === 'Object') {
      permission = _permission
    }
  }

  return JSON.parse(JSON.stringify(permission))
}

/**
 * 路由拦截
 * @param {array[object]} headerBar
 */
export const RouteInterception = headerBar => {
  // 获取路由列表
  const routes = headerBar.map(bar => {
    const key = Object.keys(bar)[0] // 路由中文名
    const value = bar[key] // 路由英文名
    return `/homepage/${value}`
  })
  // 获取当前路由
  let currentPath = window.location.pathname
  // 不等于 / ，则说明不位于首页，才需要进行拦截
  if (currentPath !== '/') {
    // 把路由全部转成 /homepage/...... 的形式
    currentPath = `/homepage/${currentPath.replace(('/homepage/'), '').replace(/\/.*/, '')}`
    // 如果路由不存在，则强行跳转到登录页面
    if (routes.includes(currentPath) === false) {
      window.location.replace('/')
    }
  }
}

/**
 * 强制修改密码的路由拦截
 */
export const RouteInterceptionFUP = () => {
  const currentPath = window.location.pathname
  if (currentPath.match(/\/homepage\/dataManagement/) === null) {
    window.location.replace('/homepage/dataManagement')
  }
}

/**
 * 双向绑定
 */
export default class Enum {
  constructor(originData) {
    this.enum = originData;
  }
  set enum(originData) {
    // 如果传入的是数组，则以下标作为 key 值
    let originObj = Array.isArray(originData) ? {
      ...originData
    } : originData;
    this.originObj = originObj;
    // 遍历值双向映射到 Enum 实例中
    for (let key in originObj) {
      this[key] = originObj[key];
      this[originObj[key]] = Number(key);
    }
  }
  // 将 Enum 传入的对象转换成 antd 表单要的 options 形式
  toOptions() {
    return Object
      .entries(this.originObj)
      .map(([value, label]) => ({
        label,
        value: +value // 字符串转为数字
      }));
  }
}

/**
 * 请求拦截
 * @param {*} url 请求地址
 * @param {*} expireAt 过期时间
 * @param {*} permissionList 权限列表
 */
export const NotInterceptRequest = (url, expireAt, permissionList) => {
  // 拦截过期token
  if (expireAt < new Date()) {
    Modal.confirm({
      title: "提示",
      content: "登陆状态已过期请重新登陆",
      onOk(close) {
        localStorage.removeItem('JessTk');
        window.location.replace('/')
        close()
      },
      onCancel(close) {
        close();
      }
    })
    return false;
  }

  // 拦截权限
  // 把类似 /api/user/updatePassword 的结构变成 updatePassword
  const currentURL = url.replace(/\/(.*)\//, '');
  if (permissionList.includes(currentURL) === false) {
    message.destroy();
    message.info('无权限进行此操作');
    return false;
  }

  // 不存在token时则强行跳到登录页
  const Token = GetToken();
  if (!Token) {
    return window.location.replace('/')
  }

  return true
}

/**
 * 上传文件
 * @param {object} data { 参数名 ： 文件 }
 * @param {*} url 上传接口
 * @param {*} expireAt token过期时间，从permission中拿
 * @param {*} permissionList 权限列表，从permission中拿
 * @param {*} callback 上传成功的回调函数
 */
export const UploadFile = (data, url, expireAt, permissionList, callback) => {
  if (NotInterceptRequest(url, expireAt, permissionList) === true) {
    message.info('文件上传中...', 1.5)
    const paramKey = Object.keys(data)[0]
    const paramValue = data[paramKey]
    const param = new FormData();
    param.append(paramKey, paramValue);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': GetToken(),
      },
    }
    axios.post(url, param, config).then(res => {
      callback && callback(res.data)
    })
  }
}

/**
 * 导出excel文件
 * @param {*} url 导出接口
 * @param {*} expireAt token过期时间，从permission中拿
 * @param {*} permissionList 权限列表，从permission中拿
 * @param {*} fileName 导出的文件名
 */
export const ExportExcel = (url, expireAt, permissionList, fileName, params, callback) => {
  if (NotInterceptRequest(url, expireAt, permissionList) === true) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': GetToken(),
      },
      responseType: 'arraybuffer'
    }
    if (params) {
      config.params = {
        ...params
      }
    }
    message.info('导出文件中...', 1.5)
    axios.get(url, config).then(res => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      link.click()
      callback && callback()
    })
  }
}

/**
 * 导出pdf文件
 * @param {*} url 导出接口
 * @param {*} expireAt token过期时间，从permission中拿
 * @param {*} permissionList 权限列表，从permission中拿
 * @param {*} fileName 导出的文件名
 */
export const ExportPdf = (url, expireAt, permissionList, fileName, params, callback) => {
  if (NotInterceptRequest(url, expireAt, permissionList) === true) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': GetToken(),
      },
      responseType: 'arraybuffer'
    }
    if (params) {
      config.params = {
        ...params
      }
    }
    message.info('导出文件中...', 1.5)
    axios.get(url, config).then(res => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/pdf'
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      link.click()
      callback && callback()
    })
  }
}