/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import { Typography, Form, Divider, DatePicker, Button, Input } from 'antd'
import { FetchState } from '@constanst';
import moment from 'moment'

const { Title } = Typography
const { Item } = Form;
const searchLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function GradeHeader(props) {
  const { studentInfoFetchState, handleSearch, dataForm, initialValue } = props;

  useEffect(() => {
    const values = JSON.parse(JSON.stringify(initialValue))
    values.schoolYear = moment(values.schoolYear, 'YYYY')
    dataForm.setFieldsValue({ ...values })
  }, [])

  return (
    <Fragment>
      <div className="page-header">
        <Title level={2}>学生成绩管理</Title>
        <div>
          <Form
            form={dataForm}
            {...searchLayout}
            name="gradeManagement"
            onFinish={values => handleSearch(values)}
          >
            <Item
              label='班级'
              name='clazz'
            >
              <Input allowClear />
            </Item>
            <Item
              label='学号'
              name='schoolNumber'
            >
              <Input allowClear />
            </Item>
            <Item
              label='姓名'
              name='name'
            >
              <Input allowClear />
            </Item>
            <Item
              label='年份'
              name='schoolYear'
            >
              <DatePicker name="date" picker="year" allowClear={false} />
            </Item>
            <Item>
              <Button
                loading={studentInfoFetchState.fetchState === FetchState.Pending}
                type="primary"
                htmlType='submit'
                style={{ marginLeft: '20px' }}>查询</Button>
            </Item>
          </Form>
        </div>
      </div>
      <Divider
        style={{ marginTop: '10px' }}
      />
    </Fragment>

  )
}