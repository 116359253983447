/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { List, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { API } from '@constanst';
import { useFetch } from '@common/hooks';
import { GetReduxItem, GetPermission } from '@utils'

const { Title } = Typography

export default function MajorPlanList() {

  // 获取具体的专业录取计划
  const [, makeMajorPlansRequest] = useFetch({
    name: 'majorPlan',
    url: API.major.getMajorPlans,
    method: 'get'
  });

  const { currentYear } = GetPermission() // 学生当前年份
  const majorPlan = GetReduxItem(['majorPlan', 'data']) // 当前录取计划

  // 如果获取 majorPlan
  useEffect(() => {
    if (majorPlan[0] === 'beforeFetch') {
      // 如果有年级没录取计划，则顺便请求录取计划
      makeMajorPlansRequest({ params: { year: currentYear } })
    }
  }, [])

  return (
    <div className="voluntary-left">
      <Title level={3}>录取计划</Title>
      <List
        bordered
        dataSource={majorPlan}
        renderItem={item => (
          <List.Item>
            <Typography.Text>
              <span className="major-name">{item.majorName}</span>
              计划招收人数：</Typography.Text> {item.planNumber}
          </List.Item>
        )}
      />
      <p className="margin-top-lg">
        <InfoCircleOutlined style={{ color: '#eb2f96', marginRight: '6px' }} />温馨提示：{majorPlan.length}个志愿选修均要填写，并不能重复选择专业
      </p>
    </div>
  )
}