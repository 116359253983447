/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useFetch } from '@common/hooks';
import { API, FetchState } from '@constanst';
import { GetPermission, GetReduxItem } from '@utils'
import { Descriptions, Table, Typography, Button, Form, Input, Divider, message, Modal } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { returnPagination } from '@utils/returnPagination'

const { Title } = Typography;

export default function ManagementInformation() {
  const { user, role } = GetPermission().data
  const managementInfo = GetReduxItem(['managementInformation', 'data'])
  const searchInfo = GetReduxItem(['managementInformation', 'searchInfo'])
  const [columns, setColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);   // 设置选中项
  const [isModalVisible, setIsModalVisible] = useState(false);   // 设置模态框

  const dispatch = useDispatch()

  const compare = (a, b) => a.name.localeCompare(b.name, 'zh')

  // 获取表格
  const [informationFetchState, makeInformationRequest] = useFetch({
    name: 'managementInformation',
    url: API.admin.manager.getAdmin,
    method: 'post',
  });

  // 删除请求
  const [deleteAdminFetchState, deleteAdminRequest] = useFetch({
    name: 'deleteAdmin',
    url: API.admin.manager.deleteAdmin,
    method: 'get',
  });

  // 新增请求
  const [addAdminFetchState, addAdminRequest] = useFetch({
    name: 'addAdmin',
    url: API.admin.manager.addAdmin,
    method: 'POST',
  });

  const [form] = Form.useForm();    // 获取表单实例
  const [searchForm] = Form.useForm();    // 获取表单实例

  // 行表头
  useEffect(() => {
    const columns = [
      {
        title: '用户名',
        dataIndex: 'name',
        sorter: {
          compare
        },
      },
      {
        title: '登录账号',
        dataIndex: 'schoolNumber',
        sorter: {
          compare
        },
      },
    ];

    searchForm.setFieldsValue({ ...searchInfo })

    setColumns(columns);
  }, []);

  // 表格请求
  useEffect(() => {
    // 如果组件挂载时，managementInfo中没有内容，则进行一次请求
    if (Object.keys(managementInfo).length === 0) {
      makeInformationRequest({
        data: {
          current: 1,
          size: 20,
        }
      })
    }
  }, [])

  useEffect(() => {
    if (informationFetchState.fetchState === FetchState.Failure) {
      message.warning('表格获取失败！', 1.5);
    }
  }, [managementInfo])

  // 删除后的事件
  useEffect(() => {
    if (deleteAdminFetchState.fetchState === FetchState.Success) {
      // 刷新(刷新后仍是搜索框内容)
      makeInformationRequest({
        data: {
          current: 1,
          size: 20,
          ...searchInfo
        }
      })
      message.success('删除成功！', 1.5);
    } else if (deleteAdminFetchState.fetchState === FetchState.Failure) {
      message.warning(deleteAdminFetchState.data || '删除失败！', 1.5);
    }
  }, [deleteAdminFetchState])

  // 新增后的事件
  useEffect(() => {
    if (addAdminFetchState.fetchState === FetchState.Success) {
      setIsModalVisible(false);
      // 刷新(刷新后仍是搜索框内容)
      makeInformationRequest({
        data: {
          current: 1,
          size: 20,
          ...searchInfo
        }
      })
      message.success('新增成功！', 1.5);
    }
    if (addAdminFetchState.fetchState === FetchState.Failure) {
      message.warning(addAdminFetchState.data || '新增失败！', 1.5);
    }
  }, [addAdminFetchState])

  // 模态框函数
  // 打开模态框
  const showModel = () => {
    setIsModalVisible(true);
    form.resetFields();   // 清空模态框里表单的值
  }

  // 取消按钮
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 模态框表单完成事件
  const handleOk = () => {
    form.validateFields().then(() => {    // 校验并发请求
      addAdminRequest({ data: form.getFieldsValue() })
    })
  };

  const onFinish = (values) => {

    makeInformationRequest({    // 发请求
      data: {
        current: 1,
        size: 20,
        ...values
      }
    })

    dispatch({
      type: 'managementInformation/setSearchInfo',
      payload: values
    })

  };

  // table选择后的数据设置在state
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  };

  const layout = {    // 模态框里的
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const searchLayout = {    // 搜索框
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onPageChange = (page, pageSize) => {
    setSelectedRowKeys({})    // 清除选中项
    makeInformationRequest({    // 刷新
      data: {
        current: page,
        size: pageSize,
        ...searchInfo
      }
    });
  }

  // 刷新表格
  const reflashTable = () => {
    searchForm.resetFields()  // 清空搜索框

    dispatch({
      type: 'managementInformation/setSearchInfo',
      payload: {}
    })

    makeInformationRequest({    // 刷新
      data: {
        current: 1,
        size: 20,
      }
    })
  }

  const deleteManager = () => {
    const srt = `${selectedRowKeys}`    // 获取删除的key
    if (selectedRowKeys.length) {
      Modal.confirm({   // 删除
        title: '删除管理员',
        content: `删除之后将不可恢复，请问是否继续删除`,
        okText: "确认",
        cancelText: "取消",
        okType: 'danger',
        maskClosable: true,
        onOk: () => {
          deleteAdminRequest({ params: { userIdList: srt } })
        }
      })
    } else {    // 如果没有选择则warning
      message.warning('请至少选择一项', 1.5);
    }
  }

  return (
    <div>
      <header className='page-header'>
        <Title level={2}>个人基本信息</Title>
      </header>
      <Divider
        style={{ marginTop: '10px' }}
      />
      {/* 基本信息标签 */}
      <Descriptions
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="姓名">{user.name}</Descriptions.Item>
        <Descriptions.Item label="权限信息">{role.description}</Descriptions.Item>
        <Descriptions.Item label="工号">{user.schoolNumber}</Descriptions.Item>
      </Descriptions>
      <div>
        <Divider
          dashed
          style={{ marginBottom: '10px' }}
        />
        {/* 用户管理header */}
        <div className="management-header" style={{ marginTop: '10px' }}>
          <Title level={3}>管理员信息</Title>
          {/* 搜索框 */}
          <Form
            {...searchLayout}
            name="searchAdmin"
            form={searchForm}
            onFinish={onFinish}
          >
            <Form.Item
              label="用户名"
              name="name"
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label="账号"
              name="schoolNumber"
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
                查询
            </Button>
            </Form.Item>
          </Form>
        </div>

        <div className="admin-table-header">
          {/* 刷新按钮 */}
          <Button
            title='刷新表格'
            style={{ marginRight: '10px' }}
            onClick={reflashTable}
          >
            <RedoOutlined />
          </Button>
          {/* 删除按钮 */}
          <Button
            style={{ marginRight: '10px' }}
            type="danger"
            onClick={deleteManager}>
            删除
          </Button>
          {/* 新增按钮 */}
          <Button type="primary" onClick={showModel}>新增</Button>
        </div>

        {/* 用户管理table */}
        <Table
          className={'admin-table'}
          rowSelection={rowSelection}   // 选中
          columns={columns}   // 表头配置
          dataSource={managementInfo?.records || informationFetchState.data?.records}   // 表单数据
          rowKey={'id'}   // 选择key值
          loading={informationFetchState.fetchState === FetchState.Pending}
          size="small"
          pagination={returnPagination(managementInfo, onPageChange)}   // 行配置
          scroll={{ y: Number(sessionStorage.getItem('tableHeight')) }}
        />
        {/* 新增管理员模态框 */}
        <Modal
          title="新增管理员"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose={true}
        >
          <Form
            {...layout}
            name="addAdmin"
            initialValues={{ remember: true }}
            form={form}
          >
            <Form.Item
              label="用户名"
              name="name"
              allowClear
              rules={[{ required: true, message: '请输入你的用户名!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="账号"
              name="schoolNumber"
              allowClear
              rules={[{ required: true, message: '请输入你的登录账号!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="密码"
              name="password"
              allowClear
              rules={[{ required: true, message: '请输入你的密码!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>

  )
}