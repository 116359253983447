import React, { Fragment, useState } from 'react'
import { Button, Modal } from 'antd'
import UpdatePasswordForm from '../UpdatePasswordForm'

export default function UpdatePasswordBtn(props) {

  // 设置模态框是否出现
  const [visible, setVisible] = useState(false)
  // 获取按钮名跟模态框名
  const { btnName = '修改密码', modalTitle = '修改密码' } = props

  return (
    <Fragment>
      <Button
        type="primary"
        htmlType="submit"
        style={{ marginTop: "20px", float: "right" }}
        onClick={() => { setVisible(true) }}
      >
        {btnName}
      </Button>

      <Modal
        title={modalTitle}
        visible={visible}
        onCancel={() => { setVisible(false) }}
        maskClosable={false}
        footer={null}
        destroyOnClose
      >
        <UpdatePasswordForm
          afterUpdate={() => { setVisible(false) }}
        />
      </Modal>
    </Fragment>
  )
}