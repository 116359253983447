import { createSlice } from '@reduxjs/toolkit';

export const gradeQuery = createSlice({
    name: 'gradeQuery',
    initialState: {
      data: {}
    },
    reducers: {
      fetchSuccess(state, action) {
        const { payload } = action;
        state.data = payload;
      },
    },
}).reducer;