import React from 'react'
import { Descriptions, Typography, Divider } from 'antd';
import UpdatePasswordBtn from '../UpdatePasswordBtn'

const { Title } = Typography;

export default function OrdinaryInformation(props) {

  const { name, description, schoolNumber } = props

  return (
    <div>
      <div className="scoreHeader page-header">
        <Title level={2}>个人基本信息</Title>
      </div>
      <Divider
        style={{ marginTop: '10px' }}
      />
      <Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}>
        <Descriptions.Item label="姓名">{name}</Descriptions.Item>
        <Descriptions.Item label="权限信息">{description}</Descriptions.Item>
        <Descriptions.Item label="工号">{schoolNumber}</Descriptions.Item>
      </Descriptions>

      <UpdatePasswordBtn />
    </div>
  )
}
