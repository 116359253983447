/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { GetPermission, GetReduxItem } from '@utils'
import { API } from '@constanst';
import { useFetch } from '@hooks';
import OrdinaryVolunteer from './OrdinaryVolunteer'
import PrincipalVolunteer from './PrincipalVolunteer'

export default function VolunteerManagement() {

  const { currentYear, data: { role } } = GetPermission()
  const managerBatch = GetReduxItem(['managerBatch', 'data'])
  const { confirm, id } = managerBatch[managerBatch.length - 1] || {}

  // 获取批次
  const [, makeQueryBatchRequest] = useFetch({
    name: 'managerBatch',
    url: API.admin.ordinary.queryBatch,
    method: 'get'
  })

  useEffect(() => {
    if (managerBatch[0] === 'beforeFetch') {
      makeQueryBatchRequest({
        params: { year: currentYear }
      })
    }
  }, [])

  return (
    <Fragment>
      {
        role.id === 2 &&
        <OrdinaryVolunteer
          batchId={id}
        />
      }
      {
        role.id === 3 &&
        <PrincipalVolunteer
          isVolunteerConfirm={confirm}
          batchId={id}
        />
      }
    </Fragment>
  )
}