/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import AddBatch from './AddBatch'
import EditBatch from './EditBatch'
import MajorPlan from './MajorPlan'
import { GetPermission, GetReduxItem } from '@utils'
import { API } from '@constanst';
import { useFetch } from '@hooks';
import './index.less'

export default function AuxiliaryManagement() {


  // 获取批次
  const [, makeQueryBatchRequest] = useFetch({
    name: 'managerBatch',
    url: API.admin.ordinary.queryBatch,
    method: 'get'
  })

  const { currentYear } = GetPermission()

  const managerBatch = GetReduxItem(['managerBatch', 'data'])

  const BatchData = managerBatch[managerBatch.length - 1] || {}

  useEffect(() => {
    if (managerBatch[0] === 'beforeFetch') {
      makeQueryBatchRequest({
        params: { year: currentYear }
      })
    }
  }, [])

  return (
    <Fragment>
      <AddBatch />
      <div style={{ margin: '50px 0' }}>
        {
          BatchData.confirm !== 1 && (
            <EditBatch
              BatchData={BatchData}
            />
          )
        }
      </div>
      <MajorPlan />
    </Fragment>
  )
}