/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { message, Spin, Button, Typography, Divider } from 'antd';
import { API, FetchState } from '@constanst';
import { useFetch } from '@hooks';
import { RedoOutlined } from '@ant-design/icons';
import { GetReduxItem, GetPermission } from '@utils'

import './index.less';

const { Title } = Typography

export default function Matriculation() {
  // 请求录取结果
  const [matriculationFetchState, makeMatriculationRequest] = useFetch({
    name: 'matriculation',
    url: API.student.queryAdmissionResult,
    method: 'get'
  })

  const matriculation = GetReduxItem(['matriculation', 'data'])

  const { role } = GetPermission().data

  const isLoading = matriculationFetchState.fetchState === FetchState.Pending;

  useEffect(() => {
    if (matriculation[0] === 'beforeFetch')
      makeMatriculationRequest()
  }, [])

  useEffect(() => {
    if (matriculationFetchState.fetchState === FetchState.Failure) {
      message.error('获取失败！', 1.5);
    }
  }, [matriculationFetchState])

  const result = [
    <div>很遗憾，你未被录取。</div>,
    <div>你的录取结果为<span className="major-name">{matriculation[matriculation.length - 1]?.majorName}</span>。</div>,
    <div>录取结果未出，请耐心等待。</div>,
    <div>请先填报志愿。</div>,
    <div>加载错误，请刷新。</div>,
  ];

  const accept = matriculation[matriculation.length - 1]?.accept
  const resultText = result[accept === undefined ? 4 : accept]

  if (role.roleName === 'special') {
    result[3] = <div>录取结果未出，请耐心等待。</div>
  }

  return (
    <div>
      <div className="matriculation-title page-header">
        <Title level={2}>录取结果查询</Title>
        <Button
          style={{ marginRight: '20px' }}
          title="刷新结果"
          onClick={() => { makeMatriculationRequest() }}
        >
          <RedoOutlined />
        </Button>
      </div>
      <Divider
        style={{ marginTop: '10px' }}
      />
      <Spin
        style={{ marginTop: '30px' }}
        spinning={isLoading}
        tip="加载中..."
      >
        {
          isLoading === false && (
            <div className="matriculation-result">
              {resultText}
            </div>
          )
        }
      </Spin>
    </div>
  )
}