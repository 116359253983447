/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import { Divider, Typography, Button, Table, InputNumber, Spin, message } from 'antd'
import { API, FetchState } from '@constanst';
import { useFetch } from '@common/hooks';
import { GetReduxItem, GetValueType, GetPermission } from '@utils'

import './index.less'

const { Title } = Typography

export default function MajorPlan() {

  // 获取具体的专业录取计划
  const [majorPlansFetchState, makeMajorPlansRequest] = useFetch({
    name: 'majorPlan',
    url: API.major.getMajorPlans,
    method: 'get'
  });

  // 修改录取计划
  const [updateOfferPlanFetchState, makeUpdateOfferPlanRequest] = useFetch({
    url: API.admin.manager.updateOfferPlan,
    method: 'post'
  })

  const { currentYear } = GetPermission()
  const majorPlan = GetReduxItem(['majorPlan', 'data']) // 当前录取计划

  // 如果获取 majorPlan
  useEffect(() => {
    if (majorPlan[0] === 'beforeFetch') {
      // 如果有年级没录取计划，则顺便请求录取计划
      makeMajorPlansRequest({ params: { year: currentYear } })
    }
  }, [])

  // 表头
  const columns = [
    {
      title: '专业名称',
      dataIndex: 'majorName',
      key: 'majorName',
    },
    {
      title: '当前录取计划',
      dataIndex: 'planNumber',
      key: 'planNumber',
    },
    {
      title: '更改为',
      dataIndex: 'plan',
      key: 'plan',
      render: (_, record) =>
        <InputNumber
          min={0}
          key={Math.random()}
          defaultValue={record.planNumber}
          onChange={value => setPlanData(record.key, value)}
        />
    }
  ]

  // 要发送请求的数据
  const planData = []

  // 表格内容
  const dataSource = majorPlan.map(item => {
    // 根据当前录取计划设置初始请求数据
    planData.push({
      majorId: item.majorId,
      plan: item.planNumber
    })

    return {
      key: item.majorId,
      majorName: item.majorName,
      planNumber: item.planNumber
    }
  })

  // 更改planData
  const setPlanData = (key, value) => {
    if (GetValueType(value) === 'Null') {
      message.error('不允许有空值', 1.5)
    }
    for (let item of planData) {
      if (item.majorId === key) {
        item.plan = value
        break
      }
    }
  }

  // 验证PlanData中是否有空值
  const validatePlanData = () => {
    for (let item of planData) {
      if (GetValueType(item.plan) === 'Null') return false
    }
    return true
  }

  // 更改录取计划
  const changePlan = () => {

    if (validatePlanData()) {

      let sameNum = 0

      planData.forEach((item, index) => {
        item.plan === majorPlan[index].planNumber && sameNum++;
      })

      if (sameNum === majorPlan.length) {
        message.destroy()
        message.error('修改计划不可与之前计划一致', 1.5)
        return
      }

      // 发送修改请求
      makeUpdateOfferPlanRequest({
        data: planData
      })
    } else {
      message.destroy()
      message.error('不允许有空值', 1.5)
    }
  }

  useEffect(() => {
    if (updateOfferPlanFetchState.fetchState === FetchState.Success) {
      message.success('修改成功', 1.5)
      // 修改成功后刷新 majorPlan
      makeMajorPlansRequest({ params: { year: currentYear } })
    }
  }, [updateOfferPlanFetchState])

  // 是否处于加载表格状态
  const isLoadingDataSource = majorPlansFetchState.fetchState === FetchState.Pending
  const isUpdatingOfferPlan = updateOfferPlanFetchState.fetchState === FetchState.Pending

  return (
    <Fragment>
      <header className='page-header'>
        {/* 标题 */}
        <Title level={2}>修改录取计划</Title>
      </header>
      <Divider
        style={{ marginTop: '10px' }}
      />
      {/* 底部 */}
      <Spin
        spinning={isLoadingDataSource}
        tip="加载中..."
      >
        {isLoadingDataSource === false && (
          <Fragment>
            <Table
              rowKey="key"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: 'right', marginTop: '20px' }}
              onClick={changePlan}
              loading={isUpdatingOfferPlan}
            >
              确认修改
            </Button>
          </Fragment>
        )}
      </Spin>
    </Fragment>
  )
}