/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import { Divider, Typography, Row, Col, Button, Spin, message, Form, Input, DatePicker } from 'antd'
import { API, FetchState } from '@constanst';
import { useFetch } from '@common/hooks';
import moment from 'moment'
import './index.less'

const { Title } = Typography
const { Item } = Form;
const { RangePicker } = DatePicker;

export default function AddBatch() {

  // 判断是否正在加载
  const isLoadingDataSource = false;

  const [addBatchFetchState, makeAddBatchRequest] = useFetch({
    url: API.admin.manager.addBatch,
    method: 'post'
  })


  useEffect(() => {
    if (addBatchFetchState.fetchState === FetchState.Success) {
      message.success('创建成功', 1.5);
    } else if (addBatchFetchState.fetchState === FetchState.Failure) {
      message.error(addBatchFetchState.data, 1.5);
    }
  }, [addBatchFetchState])


  // 确定创建批次
  const onFinish = (formData) => {
    let startTime, endTime;
    formData.time.forEach((item, index) => {
      const tmpTime = moment(item._d).format('YYYY-MM-DDTHH:mm:ss');
      if (index) {
        endTime = tmpTime;
      } else {
        startTime = tmpTime;
      }
    });
    makeAddBatchRequest({
      data: {
        startTime,
        endTime,
        batchName: formData.batchName
      }
    })
  }


  return (
    <Fragment>
      <header className='page-header'>
        {/* 标题 */}
        <Title level={2}>创建分流批次</Title>
      </header>
      <Divider
        style={{ marginTop: '10px' }}
      />
      {/* 底部 */}
      <Spin
        spinning={isLoadingDataSource}
        tip="加载中..."
      >
        <Form
          onFinish={values => { onFinish(values) }}
        >
          <Row>
            <Col span='6' key='1'>
              <Item
                label='批次名称'
                name="batchName"
                rules={[{ required: true }]}
              >
                <Input />
              </Item>
            </Col>
            <Col style={{ marginLeft: '20px' }} span='8' key='2'>
              <Item
                label='时间'
                name='time'
                rules={[{ required: true }]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Item>
            </Col>
            <Col span='2' key='3'>
              <Item>
                <Button
                  loading={addBatchFetchState.fetchState === FetchState.Pending}
                  type="primary"
                  htmlType='submit'
                >
                  确定创建
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Fragment>
  )
}