import {
  createSlice
} from '@reduxjs/toolkit';

export const permission = createSlice({
  name: 'permission',
  initialState: {
    data: {},
    currentYear: null
  },
  reducers: {
    fetchSuccess(state, action) {
      const {
        payload
      } = action;
      window.localStorage.setItem('JessTk', payload.token)
      state.data = payload;
    },
    changeConfirm(state, action) {
      const {
        payload
      } = action;
      state.data = payload
    },
    setCurrentYear(state, action) {
      const {
        payload
      } = action;
      state.currentYear = payload
    }
  },
}).reducer;