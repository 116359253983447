import React, { Fragment, useState, useEffect } from 'react'
import { Divider, Typography, DatePicker, Button, List, message } from 'antd'
import { API, FetchState } from '@constanst';
import { useFetch } from '@common/hooks';
import moment from 'moment'


const { Title } = Typography

export default function EndBatch(props) {


  const [delayBatchFetchState, makeDelayBatchRequest] = useFetch({
    url: API.admin.manager.delayBatch,
    method: 'post'
  })

  const { BatchData } = props

  const [endTime, setEndTime] = useState(null)

  const changeEndtime = () => {
    if (endTime === null) {
      message.info('请先填写时间', 1.5)
      return
    }

    makeDelayBatchRequest({
      data: {
        batchId: BatchData.id,
        endTime
      }
    })

  }

  useEffect(() => {
    if (delayBatchFetchState.fetchState === FetchState.Success) {
      message.success('修改成功', 1.5)
    }

    if (delayBatchFetchState.fetchState === FetchState.Failure) {
      message.error(delayBatchFetchState.data, 1.5)
    }
  }, [delayBatchFetchState])

  return (
    <Fragment>
      <header className='page-header'>
        {/* 标题 */}
        <Title level={2}>修改批次结束时间</Title>
      </header>
      <Divider
        style={{ marginTop: '10px' }}
      />

      <List
        bordered
      >
        <List.Item>
          当前批次：{BatchData.batchName}
        </List.Item>

        <List.Item>
          批次时间：{BatchData.startTime?.replace('T', ' ')} ~ {BatchData.endTime?.replace('T', ' ')}
        </List.Item>

        <List.Item>
          更改结束时间：
          <DatePicker
            allowClear={false}
            onChange={value => {
              setEndTime(moment(value).format('YYYY-MM-DDTHH:mm:ss'))
            }}
          />
          <Button
            style={{ marginLeft: '30px' }}
            type="primary"
            onClick={changeEndtime}
          >
            确认修改
          </Button>
        </List.Item>
      </List>

    </Fragment>
  )

}
