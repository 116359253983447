import {
  createSlice
} from '@reduxjs/toolkit';

export const admissionResult = createSlice({
  name: 'admissionResult',
  initialState: {
    data: ['beforeFetch']
  },
  reducers: {
    fetchSuccess(state, action) {
      const {
        payload
      } = action;
      state.data = payload;
    },
  },
}).reducer;