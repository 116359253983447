/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import { useFetch } from '@common/hooks';
import { API, FetchState } from '@constanst';
import { useDispatch } from 'react-redux';
import { GetPermission } from '@utils'

export default function UpdatePassword(props) {

  const dispatch = useDispatch()
  const permissionData = GetPermission().data

  // 是否强制更新密码
  const force = (permissionData.user.confirm === 0)

  const { afterUpdate } = props

  // 修改密码
  const [updatePasswordFetchState, makeUpdatePasswordRequest] = useFetch({
    url: API.user.updatePassword,
    method: 'post'
  });

  // 完成表单填写后执行该函数
  const changePassword = values => {

    if (values._newPassword !== values.newPassword) {
      message.warning('两次输入的密码不同')
      return
    }

    if (values.oldPassword === values.newPassword) {
      message.warning('新密码不能与旧密码相同')
      return
    }

    makeUpdatePasswordRequest({
      data: {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      }
    })

  }

  useEffect(() => {
    if (updatePasswordFetchState.fetchState === FetchState.Success) {
      message.success('修改成功！')
      // 如果传了修改成功后的回调函数，则执行该函数
      afterUpdate && afterUpdate()
      // 取消强制更新密码
      if (force) {
        permissionData.user.confirm = 1;
        dispatch({
          type: 'permission/changeConfirm',
          payload: permissionData
        })
      }
    }
    if (updatePasswordFetchState.fetchState === FetchState.Failure) {
      message.error(updatePasswordFetchState.data);
    }
  }, [updatePasswordFetchState])

  return (
    <div className="update-password">

      {force && <h3>{`${permissionData.user.name}，你好`}！<br />首次登录，请修改密码。</h3>}

      <Form
        name="basic"
        onFinish={values => changePassword(values)}
      >
        <Form.Item
          label="旧密码"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: '请输入旧密码！',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="新密码"
          name="_newPassword"
          rules={[
            {
              required: true,
              message: '请输入新密码！',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="确认密码"
          name="newPassword"
          rules={[
            {
              required: true,
              message: '请再次输入新密码！',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>


        <Form.Item
          wrapperCol={{
            offset: 10,
          }}
        >
          <Button
            htmlType="submit"
            type="primary"
            loading={updatePasswordFetchState.fetchState === FetchState.Pending}
          >
            确认修改
        </Button>
        </Form.Item>
      </Form>

    </div>
  )
}