/** */
export const returnPagination = (info, onChange) => {

  const {
    total,
    size,
    current
  } = info; // 从props里面拿分页器设置，根据后台返回数据

  if ((total && size && current) !== undefined) {

    const pageSize = size

    // 显示目前位置
    const showTotal = () => {

      const start = (current - 1) * pageSize + 1,
        dataEnd = current * pageSize,
        end = dataEnd > total ? total : dataEnd

      const fromTo = `${start} - ${end}`

      return `显示 第 ${end === 1 ? 1 : fromTo} 条 ， 共 ${total} 条`

    }

    const pageSizeOptions = [20, 50, 100, total].sort((a, b) => a - b)
    // 把total之后的数切除掉
    pageSizeOptions.splice(pageSizeOptions.indexOf(total) + 1, pageSizeOptions.length - 1)

    // 分页显示器配置
    const pagination = {
      total, // 数据总数
      current, // 当前页数，后台会默认第一页
      pageSize, // 每页条数
      defaultPageSize: 20, // 每页默认20条
      showSizeChanger: true, // 展示 pageSize 切换器
      showQuickJumper: true, // 显示快速跳转
      onChange, // 页码改变的回调，参数是改变后的页码及每页条数
      showTotal, // 用于显示数据总量和当前数据顺序
      pageSizeOptions, // 指定每页可以显示多少条
    }

    return pagination

  }

  return {}
}