/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment, useState } from 'react'
import { Button, Menu, Dropdown, Form, message, Modal } from 'antd'
import { Divider, Typography, Select, Input } from 'antd'
import { useFetch } from '@hooks';
import { API, FetchState } from '@constanst';
import { GetPermission, ExportExcel, GetReduxItem } from '@utils'
import { RedoOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const { Option } = Select;
const { Title } = Typography;

export default function FunctonButtons(props) {

  // 判断是否所有学生都填写完志愿
  const [isAllVoluntaryFetchState, makeIsAllVoluntaryRequest] = useFetch({
    url: API.admin.manager.isAllVoluntary,
    method: 'get'
  })

  // 确认学生志愿
  const [confirmResultFetchState, makeConfirmResultRequest] = useFetch({
    url: API.admin.manager.confirmResult,
    method: 'post'
  })

  // 分流
  const [startDiversionFetchState, makeStartDiversionRequest] = useFetch({
    url: API.admin.manager.startDiversion,
    method: 'post'
  })

  const btnLoading = isAllVoluntaryFetchState.fetchState === FetchState.Pending

  const [searchForm] = Form.useForm();  // 获取表单实例
  const permission = GetPermission() // 获取permission
  const { expireAt, permissionList } = permission.data // 获取过期时间和权限列表
  const { batchId, showBtn = false, initialValue } = props
  const studentBatchId = { batchId }
  const { onFinish } = props
  const { data: { role } } = permission

  const [allVoluntaryFunc, setAllVoluntaryFunc] = useState(null)

  const managerBatch = GetReduxItem(['managerBatch', 'data'])
  const dispatch = useDispatch()

  const searchLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const statusLayout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },
  }

  useEffect(() => {
    searchForm.setFieldsValue({ ...initialValue })
  }, [])

  // 判断是否所有人都填报了志愿
  useEffect(() => {
    if (isAllVoluntaryFetchState.fetchState === FetchState.Success) {

      if (isAllVoluntaryFetchState.data === 0) {
        message.error('请等待所有学生填报完志愿')
        return
      }

      if (allVoluntaryFunc === 'diversion') {
        diversion()
      }

      if (allVoluntaryFunc === 'confirmResult') {
        confirmResult()
      }

    }
  }, [isAllVoluntaryFetchState])

  // 分流
  useEffect(() => {

    if (startDiversionFetchState.fetchState === FetchState.Success) {
      message.success("分流成功", 1.5)
      // 2 表示已经执行分流操作，但是未确认学生志愿
      managerBatch[managerBatch.length - 1].confirm = 2
      dispatch({
        type: 'managerBatch/confirm',
        payload: managerBatch
      })
    }

    if (startDiversionFetchState.fetchState === FetchState.Failure) {
      message.error(startDiversionFetchState.data, 1.5)
    }
  }, [startDiversionFetchState])

  // 确认学生志愿
  useEffect(() => {

    if (confirmResultFetchState.fetchState === FetchState.Success) {
      message.success("确认成功", 1.5)
      // 1 表示已经确认学生志愿
      managerBatch[managerBatch.length - 1].confirm = 1
      dispatch({
        type: 'managerBatch/confirm',
        payload: managerBatch
      })
    }

    if (confirmResultFetchState.fetchState === FetchState.Failure) {
      message.error(confirmResultFetchState.data, 1.5)
    }

  }, [confirmResultFetchState])

  // 导出学生填报志愿详情
  const exportBeforeSubmittedInfo = () => {
    if (batchId === undefined) {
      message.info('当前不存在批次', 1.5)
      return
    }
    ExportExcel(API.admin.ordinary.exportVoluntaryInfo, expireAt, permissionList, '学生填报志愿详情', studentBatchId)
  }

  // 导出全部学生填报志愿结果
  const exportSubmittedInfo = () => {
    if (batchId === undefined) {
      message.info('当前不存在批次', 1.5)
      return
    }
    ExportExcel(API.admin.ordinary.exportVoluntaryResult, expireAt, permissionList, '学生志愿报名结果', studentBatchId)
  }

  // 导出未填报志愿学生的信息
  const exportNotSubmittedInfo = () => {
    if (batchId === undefined) {
      message.info('当前不存在批次', 1.5)
      return
    }
    ExportExcel(API.admin.ordinary.exportNotSubmittedStudentInfo, expireAt, permissionList, '未填报志愿学生信息', studentBatchId)
  }

  // 刷新表格
  const reflashTable = () => {
    if (batchId === undefined) {
      message.info('当前不存在批次', 1.5)
      return
    }
    const { makeRequest } = props
    searchForm.resetFields()  // 清空搜索框
    makeRequest({  // 刷新
      data: {
        current: 1,
        size: 20,
        batchId
      }
    })

    dispatch({
      type: 'ordinaryVolunteer/setSearchInfo',
      payload: {}
    })

    dispatch({
      type: 'principleVolunteer/setSearchInfo',
      payload: {}
    })

  }

  let menu = (
    <Menu>
      <Menu.Item
        onClick={exportNotSubmittedInfo}
      >
        未填报学生信息
    </Menu.Item>
    </Menu>
  )

  if (role.id === 3) {
    menu = (
      <Menu>
        <Menu.Item
          onClick={exportBeforeSubmittedInfo}
        >
          填报志愿详情
      </Menu.Item>
        <Menu.Item
          disabled={managerBatch[managerBatch.length - 1]?.confirm === 1 ? false : true}
          title={managerBatch[managerBatch.length - 1]?.confirm === 0 && '确认志愿后方可导出'}
          onClick={exportSubmittedInfo}
        >
          志愿报名结果
        </Menu.Item>
        <Menu.Item
          onClick={exportNotSubmittedInfo}
        >
          未填报学生信息
      </Menu.Item>
      </Menu>
    )
  }

  // 分流
  const diversion = function () {
    makeStartDiversionRequest({
      data: {
        batchId
      }
    })
  }

  // 确认志愿
  const confirmResult = () => {
    Modal.confirm({
      title: '确认学生志愿',
      content: `确认操作不可撤销，确认继续？`,
      okText: "确认",
      cancelText: "取消",
      okType: 'danger',
      onOk: () => {
        makeConfirmResultRequest({
          data: {
            batchId
          }
        })
      }
    })
  }

  return (
    <div>

      <Fragment>
        <div className="management-header">
          <Title level={2}>学生填报志愿情况</Title>
          {/* 搜索框 */}
          <Form
            {...searchLayout}
            name="searchAdmin"
            form={searchForm}
            onFinish={onFinish}
          >
            <Form.Item
              label="班级"
              name="clazz"
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label="学号"
              name="schoolNumber"
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label="姓名"
              name="name"
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              label="是否提交"
              name="status"
              style={{ width: '150px' }}
              {...statusLayout}
            >
              <Select allowClear >
                <Option value="1">否</Option>
                <Option value="2">是</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }}>
                查询
            </Button>
            </Form.Item>
          </Form>
        </div>
        <Divider
          style={{ marginTop: '10px' }}
        />
      </Fragment>

      <div className="admin-table-header">

        {/* 刷新按钮 */}
        <Button title='刷新表格' onClick={reflashTable}>
          <RedoOutlined />
        </Button>

        {/* 导出按钮 */}
        <Dropdown overlay={menu}>
          <Button style={{ margin: '0 10px' }}>导出</Button>
        </Dropdown>

        {/* 确认志愿按钮 */}
        {
          showBtn === true && (
            <Fragment>

              <Button
                style={{ marginRight: '10px', width: '80px'}}
                type="primary"
                ghost
                loading={allVoluntaryFunc === 'diversion' && btnLoading}
                onClick={() => {
                  // 把 allVoluntaryFunc 设为分流操作
                  setAllVoluntaryFunc('diversion')
                  makeIsAllVoluntaryRequest({
                    params: { batchId }
                  })
                }}
              >
                分流
              </Button>

              <Button
                title="确定该志愿填报"
                danger={true}
                loading={allVoluntaryFunc === 'confirmResult' && btnLoading}
                style={{ width: "100px" }}
                onClick={() => {
                  // 把 allVoluntaryFunc 设为确认操作
                  setAllVoluntaryFunc('confirmResult')
                  makeIsAllVoluntaryRequest({
                    params: { batchId }
                  })
                }}
              >
                确认志愿
            </Button>
            </Fragment>
          )
        }
      </div>
    </div >
  )
}