/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react'
import { Descriptions, Spin, Button, message, Divider, Typography } from 'antd'
import { useFetch } from '@common/hooks'
import { API, FetchState } from '@constanst'
import { GetReduxItem, GetPermission } from '@utils'
import UpdatePasswordBtn from '../UpdatePasswordBtn'
import { useDispatch } from 'react-redux'

const { Title } = Typography

export default function ConfirmInfo(props) {

  // 从reudx上获取用户信息
  const info = GetReduxItem(['info', 'data'])
  const permissionData = GetPermission().data
  const dispatch = useDispatch()

  // 查询学生信息
  const [InfoRequestFetchState, makeInfoRequest] = useFetch({
    name: 'info',
    method: 'get',
    url: API.student.getStudentInfo
  })

  // 确认学生信息
  const [confirmRequestFetchState, makeConfirmRequest] = useFetch({
    method: 'get',
    url: API.student.confirmInfo
  })

  // 首次挂在组件时加载学生信息
  useEffect(() => {
    // 如果没有信息则进行请求
    if (Object.keys(info).length === 0) {
      makeInfoRequest()
    }
  }, []);

  // 确认学生信息请求状态改变时执行
  useEffect(() => {
    if (confirmRequestFetchState.fetchState === FetchState.Success) {
      message.success("确认成功", 1.5)
      // 更新redux状态
      // 如果个人信息和成绩均未确认(0)，则修改infoConfirm为(1)已确认信息
      // 如果已确认个人信息(2),则修改infoConfirm为(3)表示两者均已确认
      permissionData.infoConfirm = (permissionData.infoConfirm === 0 ? 1 : 3)
      dispatch({
        type: 'permission/changeConfirm',
        payload: permissionData
      })
    }
    if (confirmRequestFetchState.fetchState === FetchState.Failure) {
      message.error(confirmRequestFetchState.data, 1.5);
    }
  }, [confirmRequestFetchState])

  // 加载状态
  const mesLoading = InfoRequestFetchState.fetchState === FetchState.Pending
  const btnLoading = confirmRequestFetchState.fetchState === FetchState.Pending
  // 是否需要确认信息 0 - 需要   1 - 不需要
  const { infoConfirm } = props

  return (
    <Fragment>
      <Spin
        spinning={mesLoading}
        tip="加载中..."
      >
        {
          mesLoading === false && (
            <Fragment>
              <header className='page-header'>
                <Title level={2}>学生信息</Title>
              </header>
              <Divider
                style={{ marginTop: '10px' }}
              />
              <Descriptions
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label="姓名">{info.name}</Descriptions.Item>
                <Descriptions.Item label="性别">{info.sex === 0 ? '女' : '男'}</Descriptions.Item>
                <Descriptions.Item label="生源地">{info.location}</Descriptions.Item>
                <Descriptions.Item label="班级">{info.clazz}</Descriptions.Item>
                <Descriptions.Item label="学号">{info.schoolNumber}</Descriptions.Item>
                <Descriptions.Item label="特殊批次">{info.batchType === 0 ? '否' : '是'}</Descriptions.Item>
                {
                  [0, 2].includes(infoConfirm) &&
                  <Descriptions.Item label="确认">
                    如信息有误，请联系管理员进行修改！信息无误请确认。
									<Button
                      style={{ width: "100px" }}
                      loading={btnLoading}
                      type="danger"
                      onClick={() => { makeConfirmRequest() }}
                    >
                      确认信息
									</Button>

                  </Descriptions.Item>
                }
              </Descriptions>

              <UpdatePasswordBtn />
            </Fragment>
          )
        }
      </Spin>
    </Fragment>
  )
}
