/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, message, Typography, Divider } from 'antd';
import { API, FetchState } from '@constanst';
import { GetReduxItem } from '@utils'
import { useFetch } from '@hooks';

import './style.less';

const { Title } = Typography;
export default function HistoricalEnrollment() {
  const [columns, setColumns] = useState([]);

  const tableData = GetReduxItem(['historicalEnrollment', 'data'])

  const [enrollmentFetchState, makeEnrollmentRequest] = useFetch({
    name: 'historicalEnrollment',
    url: API.major.getHistoricalEnrollment,
    method: 'get'
  });

  useEffect(() => {
    const columns = [
      {
        title: '年份',
        dataIndex: 'schoolYear',
        sorter: {
          compare: (a, b) => a.schoolYear - b.schoolYear,   //用于作排序
        },
      },
      {
        title: '专业名称',
        dataIndex: 'name',
        sorter: {
          compare: (a, b) => a.name - b.name,
        },
      },
      {
        title: '计划招生',
        dataIndex: 'planNumber',
        sorter: {
          compare: (a, b) => a.planNumber - b.planNumber,
        },
      },
      {
        title: '实际录取',
        dataIndex: 'actualNumber',
        sorter: {
          compare: (a, b) => a.actualNumber - b.actualNumber,
        },
      },
      {
        title: '最低排名',
        dataIndex: 'lowestRanked',
        sorter: {
          compare: (a, b) => a.lowestRanked - b.lowestRanked,
        },
      }
    ];
    setColumns(columns);
    if (tableData === null) {
      makeEnrollmentRequest();
    }
  }, []);

  useEffect(() => {
    if (enrollmentFetchState.fetchState === FetchState.Failure) {
      message.error('获取失败！', 1.5);
    }
  }, [enrollmentFetchState])

  const isLoading = enrollmentFetchState.fetchState === FetchState.Pending

  return (
    <div>
      <div className="scoreHeader page-header">
        <Title level={2}>历年招生情况</Title>
      </div>
      <Divider
        style={{ marginTop: '10px' }}
      />
      <div>
        <Table
          rowSelection={false}
          columns={columns}
          dataSource={tableData}
          loading={isLoading}
          size="small"
          pagination={{ pageSize: 20 }}
          rowKey={record => `${record.schoolYear}-${record.name}`}
          scroll={{ y: Number(sessionStorage.getItem('tableHeight')) }}
        />
      </div>

    </div>
  )
}
